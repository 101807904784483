import fetch from 'utils/fetch';

/**用户列表 */
export function listUsers() {
  return fetch({
    url: '/api/sys/dic/getUserList',
    method: 'get',
    params: {}
  });
}

/**国家列表 */
export function getCountryList() {
  return fetch({
    url: '/api/sys/dic/getCountryList',
    method: 'get',
    params: {}
  });
}

/**支付方式 */
export function getfinanceOptionList() {
  return fetch({
    url: '/api/sys/dic/financeOptionPage',
    method: 'get',
    params: {}
  });
}

/**支付账号 */
export function getPaymentAccounList() {
  return fetch({
    url: '/api/sys/dic/listPaymentAccount',
    method: 'get',
    params: {}
  });
}

/**amazon 站点 */
export function getAccountCompanyList(query) {
  return fetch({
    url: '/api/promote/financeAccountCompany/condictionList',
    method: 'get',
    params: query
  });
}

/**币别列表 dicValue 国家；dicValue 币别； */
export function getCountryCurrencyList() {
  return fetch({
    url: '/api/sys/dic/getCountryCurrencyList',
    method: 'get'
  });
}

/** Asin List */
export function getAsinList(query) {
  return fetch({
    url: '/api/sys/dic/querySoOrderAllAsin',
    method: 'get',
    params: query
  });
}

/** msku List */
export function getMskuList(query) {
  return fetch({
    url: '/api/sys/dic/getMskuByAsin',
    method: 'get',
    params: query
  });
}
//礼品卡业务-查询国家和货币
export function selectBaseCountry(query) {
  return fetch({
    url: '/api/base/baseCountry/selectBaseCountry',
    method: 'get',
    params: query
  });
}

export function getBaseFieldsConfig(type, params = {}) {
  return fetch({
    url: `/api/base/baseFieldsConfig/${type}`,
    method: 'get',
    params
  });
}

export function putBaseFieldsConfig(type, data = []) {
  return fetch({
    url: `/api/base/baseFieldsConfig/${type}/saveField`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify(data)
  });
}
