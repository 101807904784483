import { getBaseFieldsConfig, putBaseFieldsConfig } from '@/api/common/sysRemoteDic';

export default {
  state: {
    tableFieldMaps: {}
  },
  mutations: {
    SET_TABLE_FIELD_MAPS: (state, { key, data }) => {
      state.tableFieldMaps = { ...state.tableFieldMaps, [key]: data };
    }
  },
  actions: {
    getTableField({ commit, state }, { key, refresh }) {
      if (state.tableFieldMaps[key] && !refresh) {
        return state.tableFieldMaps[key];
      }

      return new Promise((resolve, reject) => {
        getBaseFieldsConfig(key)
          .then(res => {
            const { data = [] } = res;
            commit('SET_TABLE_FIELD_MAPS', { key, data });
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    setTableField({ dispatch }, { key, data }) {
      return new Promise((resolve, reject) => {
        putBaseFieldsConfig(key, data)
          .then(res => {
            dispatch('getTableField', { key, refresh: true });
            resolve(res.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    }
  },
  getters: {
    tableFieldMaps: state => state.tableFieldMaps
  }
};
