import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token';
const UserIdKey = 'UserId';
const UserNameKey = 'UserName';

export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

export function getUserId() {
  return Cookies.get(UserIdKey);
}

export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId);
}

export function removeUserId() {
  return Cookies.remove(UserIdKey);
}

export function setUserName(userName) {
  return Cookies.set(UserNameKey, userName);
}

export function removeUserName() {
  return Cookies.remove(UserNameKey);
}

/*
 * 设置setLocalStorage
 * */
export function setLocalStorage(key, value) {
  window.localStorage.setItem(key, window.JSON.stringify(value));
}
/*
 * 获取getLocalStorage
 * */
export function getLocalStorage(key) {
  return window.JSON.parse(window.localStorage.getItem(key) || '""');
}
/*
 * 设置setSessionStorage
 * */
export function setSessionStorage(key, value) {
  window.sessionStorage.setItem(key, window.JSON.stringify(value));
}
/*
 * 获取getSessionStorage
 * */
export function getSessionStorage(key) {
  return window.JSON.parse(window.sessionStorage.getItem(key) || '""');
}

export function setCookies(key, value) {
  return Cookies.set(key, window.JSON.stringify(value));
}

export function getCookies(key) {
  return window.JSON.parse(Cookies.get(key) || '""');
}
