<template>
  <div class="tabs-view-container">
    <router-link class="tabs-view" v-for="tag in visitedViews.slice(0, CRITICAL + 1)" :to="tag.path" :key="tag.path">
      <div
        class="view-tag"
        :class="tag.name == $route.name ? 'checkedMenu' : ''"
        v-if="tag.name !== '其他上报新增'"
        :closable="true"
        :type="isActive(tag.path) ? 'primary' : ''"
      >
        {{ tag.name }}

        <span v-if="tag.path !== '/'" class="close" @click="closeViewTabs(tag, $event)">X</span>
      </div>
    </router-link>

    <el-dropdown class="tabs-view-more" v-if="visitedViews.length > CRITICAL + 1">
      <span>
        <i class="el-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="tag in visitedViews.slice(CRITICAL + 1)" :key="tag.path">
          <router-link :to="tag.path">
            <div
              class="more-tag"
              v-if="tag.name !== '其他上报新增'"
              :closable="true"
              :type="isActive(tag.path) ? 'primary' : ''"
            >
              {{ tag.name }}
            </div>
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import Bus from './bus';
const CRITICAL = 6;
export default {
  data() {
    return {
      CRITICAL,
      checkedMenu: undefined
    };
  },
  created() {
    Bus.$on('checkedName', checkedName => {
      this.checkedMenu = checkedName;
    });
  },
  computed: {
    visitedViews() {
      const home = { name: '首页', path: '/' };
      let { visitedViews = [] } = this.$store.state.app;
      visitedViews = [...visitedViews.filter(value => value.path !== '/')];
      const showMenu = visitedViews.slice(-CRITICAL);
      const foldMenu = visitedViews.slice(0, -CRITICAL);
      const view = foldMenu.find(value => value.name === this.$route.name);
      if (view) {
        const index = foldMenu.findIndex(value => value.name === this.$route.name);
        const first = showMenu.shift();
        showMenu.push(view);
        foldMenu.splice(index, 1);
        foldMenu.push(first);
      }
      return [home, ...showMenu, ...foldMenu];
    }
  },
  methods: {
    closeViewTabs(view, $event) {
      this.$store.dispatch('delVisitedViews', view);
      $event.preventDefault();
    },
    generateRoute() {
      if (this.$route.matched[this.$route.matched.length - 1].name) {
        return this.$route.matched[this.$route.matched.length - 1];
      }
      this.$route.matched[0].path = '/';
      return this.$route.matched[0];
    },
    addViewTabs() {
      this.$store.dispatch('addVisitedViews', this.generateRoute());
    },
    isActive(path) {
      return path === this.$route.path;
    }
  },

  watch: {
    $route() {
      this.addViewTabs();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" slot-scope>
.tabs-view-container {
  float: left;
  vertical-align: top;
}
.view-tag {
  float: left;
  margin: 12px 10px;
  padding: 0 10px;
  line-height: 26px;
  border-right: 1px solid #ccc;
  font-size: 14px;
  color: #929292;

  &.checkedMenu {
    font-weight: bold;
    color: #333;
  }

  .close {
    margin-left: 10px;
    font-size: 12px;
    color: #ccc;

    &:hover {
      color: #666;
    }
  }
}

.more-tag {
  line-height: 36px;
  font-size: 14px;
}

.tabs-view-more {
  margin-left: 10px;
  cursor: pointer;
}
</style>
