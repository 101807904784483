import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/account',
    component: Layout,
    name: '账号状况',
    authority: 'account',
    redirect: '/account/account-task',
    children: [
      {
        path: 'account-task',
        component: () => import('@/views/account/task-list'),
        name: '任务配置',
        authority: 'account-task'
      },
      {
        path: 'account-notice',
        component: () => import('@/views/account/notice-list'),
        name: '业绩通知列表',
        authority: 'account-notice'
      },
      {
        path: 'store-info',
        component: () => import('@/views/account/store-info'),
        name: '店铺概况',
        authority: 'store-info'
      },
      {
        path: 'store-detail',
        component: () => import('@/views/account/store-detail'),
        name: '店铺详情',
        authority: 'store-detail'
      },
      {
        path: 'store-list',
        component: () => import('@/views/account/store-list'),
        name: '店铺列表',
        authority: 'store-list'
      }
    ]
  }
];
