import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      Message.error(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};
// 工单跟进-查询
export function getQueryDetailByCondition(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/queryDetailByCondition',
    method: 'get',
    params
  });
}
// 工单跟进-工单明细枚举
export function getPlanOrderDetailStatusEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/PlanOrderDetailStatusEnum',
    method: 'get',
    params
  });
}
// 工单跟进-评价枚举
export function getOrderReviewerStatusEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/OrderReviewerStatusEnum',
    method: 'get',
    params
  });
}
// 工单跟进 - 平台枚举
export function getQueryExitPlatform(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/queryExitPlatform',
    method: 'get',
    params
  });
}
// 工单跟进-归档
export function postOrderArchive(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/orderArchive',
    method: 'post',
    data
  });
}
// 百灵鸟-操作日志查询 （包括跟进日志）
export function getQueryBizLog(params = {}) {
  return fetch({
    url: '/api/base/baseBizLog/queryBizLog',
    method: 'get',
    params
  });
}
// 工单跟进-跟进人下拉列表
export function getQueryUpdateUser(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/queryUpdateUser',
    method: 'get',
    params
  });
}
// 工单跟进-关联订单下拉
export function getQueryAssociatedOrder(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/queryAssociatedOrder',
    method: 'get',
    params
  });
}
// 工单跟进-保存
export function postSavePlanOrderDetail(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/savePlanOrderDetail',
    method: 'post',
    data
  });
}
// 工单跟进-跟进日志保存
export function postAddBizLog(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/saveOrderBizLog',
    method: 'post',
    data
  });
}

// 工单跟进 - 关联订单
export function postMergeOrder(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/mergeOrder',
    method: 'post',
    data
  });
}

// 工单跟进 - 导出工单
export function postExportPlanOrderDetail(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/exportPlanOrderDetail',
    method: 'get',
    params
  });
}
