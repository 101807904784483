import request from 'utils/fetch';
import { Message } from 'element-ui';
import { getToken } from 'utils/auth';
import { getValueType } from '@/utils/index';

import axios from 'axios';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200 || getValueType(res) === 'Blob') {
      return res;
    } else {
      console.log(res);
      Message.error(res && res.resDes ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};
// 海外仓-查询
export function getPlanOrderList(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/planOrderList',
    method: 'get',
    params
  });
}

// 海外仓-修改弹出信息
export function getActualGift(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/getActualGift',
    method: 'get',
    params
  });
}

// 海外仓-寄信状态
export function getSendLetterStatusEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/SendLetterStatusEnum',
    method: 'get',
    params
  });
}

// 海外仓-生成邀请码
export function putCreateInvitationCode(id, data = {}) {
  return fetch({
    url: `/api/reviewer/blnPlanOrder/${id}/createInvitationCode`,
    method: 'put',
    data
  });
}

// 海外仓-待寄信（操作）
export function postUpdateSendLetterStatus(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/updateSendLetterStatus',
    method: 'post',
    data
  });
}

// 海外仓-导出地址
export function postExportAddress(data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        '/api/reviewer/blnPlanOrderDetail/exportAddress',
        { ...data },
        { responseType: 'blob', headers: { Authorization: getToken() } }
      )
      .then(res => {
        let { data } = res;
        let fileName = '';
        let contentDisposition = res.headers['content-disposition'];
        if (contentDisposition) {
          fileName = window.decodeURI(res.headers['content-disposition'].split("''")[1].replace('.zip', ''), 'UTF-8');
        }
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 海外仓-导出费用
export function postExportOrderFee(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/exportOrderFee',
    method: 'post',
    responseType: 'blob',
    data
  });
}

// 海外仓-修改礼物保存
export function postSaveActualGift(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/saveActualGift',
    method: 'post',
    data
  });
}

// 海外仓-批量寄信
export function postBatchSendLetter(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/batchSendLetter',
    method: 'post',
    data
  });
}

// 海外仓-导入费用
export function postImportOrderFee(data) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/importOrderFee',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}

// 上传截图
export function postUploadImage(data) {
  return fetch({
    url: '/api/tool/fastDfsClient/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}
