import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/tool',
    component: Layout,
    name: '工具',
    authority: 'tool',
    redirect: '/tool/image-info',
    children: [
      {
        path: 'image-info',
        component: () => import('@/views/tool/image-info'),
        name: '沃尔玛图片',
        authority: 'image-info'
      }
    ]
  }
];
