<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'APP'
};
</script>

<style lang="scss">
@import './styles/index.scss'; // 全局自定义的css样式
</style>

<style>
#nprogress .spinner {
  display: none !important;
}
</style>
