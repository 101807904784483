import fetch from 'utils/fetch';

export function page(query) {
  return fetch({
    url: '/api/admin/user/page',
    method: 'get',
    params: query
  });
}

/**查询用户列表 */
export function getUserPage(query) {
  return fetch({
    url: '/api/admin/user/getUserPage',
    method: 'get',
    params: query
  });
}

export function addObj(obj) {
  return fetch({
    url: '/api/admin/user/addUser',
    method: 'post',
    data: obj
  });
}

export function getObj(id) {
  return fetch({
    url: '/api/admin/user/' + id,
    method: 'get'
  })
}

export function deleteUserForCrm(obj) {
  return fetch({
    url: '/api/admin/user/deleteUserForCrm',
    method: 'post',
    data: obj
  })
}

export function putObj(id, obj) {
  return fetch({
    url: '/api/admin/user/' + id,
    method: 'put',
    data: obj
  })
}

export function listAllPersonalTasks() {
  return fetch({
    url: '/api/admin/user/listAllPersonalTasks',
    method: 'post',
    data: {}
  })
}

export function listAllPersonalTasksAndNotPass() {
  return fetch({
    url: '/api/admin/user/listAllPersonalTasksAndNotPass',
    method: 'post',
    data: {}
  })
}
//管理员切换用户
export function directLogin(query) {
  return fetch({
    url: '/api/auth/jwt/directLogin',
    method: 'get',
    params: query
  })
}