import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      Message.error(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};
// 查询所有用户
export function getUserByUserName(query) {
  return fetch({
    url: '/api/base/baseUser/getUserByUserName',
    method: 'get',
    params: query
  });
}

// 账号管理
// 账号管理页面查询
export function getAccounts(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/accounts',
    method: 'get',
    params: query
  });
}
// 禁用账号
export function disableAccount(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/disable',
    method: 'get',
    params: query
  });
}
// 启用账号
export function enableAccount(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/enable',
    method: 'get',
    params: query
  });
}
// 校验账号是否授权
export function checkConnect(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/connect',
    method: 'get',
    params: query
  });
}
// 邮箱重复性校验
export function checkRepeat(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/checkRepeat',
    method: 'get',
    params: query
  });
}
// 邮箱协议列表
export function serverList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterBasicConf/serverList',
    method: 'get',
    params: query
  });
}
// 新增账号
export function addEmail(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/add',
    method: 'get',
    params: query
  });
}
// 编辑账号
export function editEmail(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/edit',
    method: 'get',
    params: query
  });
}
// 修改密码
export function changePwd(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/changePwd',
    method: 'get',
    params: query
  });
}
// 品牌名下拉查询
export function getBrands(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterBrand/brands',
    method: 'get',
    params: query
  });
}
// 更新授权状态
export function updConnect(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAccount/updConnect',
    method: 'get',
    params: query
  });
}

// 规则管理
// 页面查询
export function getRules(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAssignRule/pageList',
    method: 'get',
    params: query
  });
}
// 查询所有条件
export function getAllfactors(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAssignRule/factor',
    method: 'get',
    params: query
  });
}
// 新增或编辑规则
export function addorUpdate(obj) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAssignRule/addorUpdate',
    method: 'post',
    data: obj
  });
}
// 删除/停用/启用规则
export function updateStatus(obj) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterAssignRule/updateStatus',
    method: 'put',
    data: obj
  });
}
// 查询规则详情
export function getRulesDetails(id) {
  return fetch({
    url: `/api/crm-mail-center/mailCenterAssignRule/${id}/detail`,
    method: 'get'
  });
}

//发件列表
//发件列表分页查询
export function sentList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterOutbox/sentList',
    method: 'get',
    params: query
  });
}

// 标签管理
// 查询标签
export function pageList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterLabel/pageList',
    method: 'get',
    params: query
  });
}
// 新增标签
export function tagAdd(obj) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterLabel/add',
    method: 'post',
    data: obj
  });
}

// 删除标签
export function tagDelete(id) {
  return fetch({
    url: `/api/crm-mail-center/mailCenterLabel/${id}/del`,
    method: 'delete'
  });
}

// 根据名称模糊查询标签
export function getLabelByName(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterLabel/getLabelByName',
    method: 'get',
    params: query
  });
}
// 根据名称模糊查询标签
export function checkLabelExist(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterLabel/checkLabelExist',
    method: 'get',
    params: query
  });
}

// 邮件池
// 邮件池页面查询
export function poolList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterRoom/poolList',
    method: 'get',
    params: query
  });
}
// 变更处理人
export function changeCustomerUser(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterRoom/changeCustomerUser',
    method: 'get',
    params: query
  });
}
// 变更处理状态
export function changeStatus(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterRoom/changeStatus',
    method: 'get',
    params: query
  });
}

//草稿箱
//草稿查询
export function draftList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterOutbox/draftList',
    method: 'get',
    params: query
  });
}
//草稿删除
export function delDraft(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterOutbox/delDraft',
    method: 'get',
    params: query
  });
}

//拉取配置
//分页查询拉取异常邮件
export function getErrorMailList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterPullError/errorMailList',
    method: 'get',
    params: query
  });
}

//获取自动拉取时间
export function getAutoPullTime(query) {
  return fetch({
    url: '/api/base/baseVariable/getValueByName',
    method: 'get',
    params: query
  });
}

//配置自动拉取时间
export function setAutoPullTime(query) {
  return fetch({
    url: '/api/base/baseVariable/updateValueByName',
    method: 'put',
    params: query
  });
}

//配置手动拉取
export function handlePull(obj) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterPullLog/handlePull',
    method: 'post',
    data: obj
  });
}

//分页查询拉取记录
export function getPullLog(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterPullLog/pullLogList',
    method: 'get',
    params: query
  });
}

//模板中心
//列表查询
export function getTemplateList(query) {
  return fetch({
    url: '/api/crm-mail-center/mailCenterTemplate/templateList',
    method: 'get',
    params: query
  });
}
