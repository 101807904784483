import Vue from 'vue';
import VueRouter from 'vue-router';
import report from './report';
import lark from './lark';
import mail from './mail';
import tool from './tool';
import kol from './kol';
import sale from './sale';
import account from './account';
import psi from './psi';
import hive from './hive';
const _import = require('./_import_production');

Vue.use(VueRouter);

import Layout from '../views/layout/Layout';

export const constantRouterMap = [
  {
    path: '/login',
    component: _import('login/index'),
    hidden: true
  },
  {
    path: '/authredirect',
    component: _import('login/authredirect'),
    hidden: true
  },
  {
    path: '/404',
    component: _import('error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: _import('error/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: '首页',
    hidden: true,
    children: [
      {
        path: 'dashboard',
        component: _import('dashboard/index')
      }
    ]
  },
  {
    path: '/introduction',
    component: Layout,
    redirect: '/introduction/index',
    icon: 'form',
    noDropdown: true,
    children: [
      {
        path: 'index',
        component: _import('introduction/index'),
        name: '简述'
      }
    ]
  },
  {
    path: '/print-payment',
    component: _import('print/payment'),
    hidden: true
  }
];

export default new VueRouter({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
});

export const asyncRouterMap = [
  ...report,
  ...lark,
  ...mail,
  ...tool,
  ...kol,
  ...sale,
  ...account,
  ...psi,
  ...hive,
  {
    path: '/baseManager',
    component: Layout,
    name: '基础配置管理',
    icon: 'setting',
    authority: 'baseManager',
    children: [
      {
        path: 'userManager',
        icon: 'fa-user',
        component: resolve => require(['@/views/admin/user/user'], resolve),
        name: '用户管理',
        authority: 'userManager'
      },
      {
        path: 'menuManager',
        icon: 'category',
        component: resolve => require(['@/views/admin/menu/menu'], resolve),
        name: '菜单管理',
        authority: 'menuManager'
      },
      {
        path: 'groupManager',
        icon: 'group_fill',
        component: resolve => require(['@/views/admin/group/group'], resolve),
        name: '角色权限管理',
        authority: 'groupManager'
      },
      {
        path: 'groupTypeManager',
        icon: 'fa-users',
        component: resolve => require(['@/views/admin/groupType/groupType'], resolve),
        name: '角色类型管理',
        authority: 'groupTypeManager'
      },
      {
        path: 'gateLogManager',
        icon: 'viewlist',
        component: resolve => require(['@/views/admin/gateLog/gateLog'], resolve),
        name: '操作日志管理',
        authority: 'gateLogManager'
      },
      {
        path: 'vtEmployeeManager',
        component: resolve => require(['@/views/admin/employeeDemo'], resolve),
        name: 'VT员工列表',
        authority: 'vtEmployeeManager'
      }
    ]
  },
  {
    path: '/authManager',
    component: Layout,
    name: '基础配置管理',
    icon: 'setting',
    authority: 'authManager',
    children: [
      {
        path: 'serviceManager',
        component: resolve => require(['@/views/auth/service/service'], resolve),
        name: '用户管理',
        authority: 'serviceManager'
      }
    ]
  },
  {
    path: '/monitorManager',
    component: Layout,
    name: '监控模块管理',
    icon: 'setting',
    authority: 'monitorManager',
    children: [
      {
        path: 'serviceEurekaManager',
        component: resolve => require(['@/views/monitor/eureka/eureka'], resolve),
        name: 'Eureka注册中心',
        authority: 'serviceEurekaManager'
      },
      {
        path: 'serviceMonitorManager',
        component: resolve => require(['@/views/monitor/service/service'], resolve),
        name: '服务状态监控',
        authority: 'serviceMonitorManager'
      },
      {
        path: 'serviceZipkinManager',
        component: resolve => require(['@/views/monitor/zipkin/zipkin'], resolve),
        name: '服务状态监控',
        authority: 'serviceZipkinManager'
      }
    ]
  },
  {
    path: '/reportCenter',
    component: Layout,
    name: '报告任务中心',
    icon: 'setting',
    authority: 'reportCenter',
    children: [
      {
        path: 'templateImportManager',
        component: resolve => require(['@/views/reportCenter/templateImport'], resolve),
        name: '模板上传',
        authority: 'templateImportManager'
      },
      {
        path: 'reportDownloadManager',
        component: resolve => require(['@/views/reportCenter/reportDownload'], resolve),
        name: '报告下载',
        authority: 'reportDownloadManager'
      }
    ]
  },
  {
    path: '/costReport',
    component: Layout,
    name: '推广费用报表',
    authority: 'costReport',
    children: [
      {
        path: 'promoteCostReport',
        component: resolve => require(['@/views/reportCenter/promoteCost-report'], resolve),
        name: '各业务花费报表',
        authority: 'promoteCostReport'
      },
      {
        path: 'month-report',
        component: resolve => require(['@/views/reportCenter/month-report'], resolve),
        name: '各业务类型费用月报表',
        authority: 'month-report'
      }
    ]
  },
  {
    path: '/financeManager',
    component: Layout,
    name: '财务管理',
    icon: 'setting',
    authority: 'financeManager',
    children: [
      {
        path: 'paymentAccountManager',
        component: resolve => require(['@/views/finance/financePaymentAccount'], resolve),
        name: '付款账号',
        authority: 'paymentAccountManager'
      }
    ]
  },
  {
    path: '/reviewerManager',
    component: Layout,
    name: '用户中心',
    icon: 'setting',
    authority: 'reviewerManager',
    children: [
      {
        path: 'reviewerEmailManager',
        component: resolve => require(['@/views/reviewer/reviewerEmailManager'], resolve),
        name: '邮件提醒客户',
        authority: 'reviewerEmailManager'
      },
      {
        path: 'reviewerUserManager',
        component: resolve => require(['@/views/reviewer/reviewerFbUser'], resolve),
        name: '客人列表',
        authority: 'reviewerUserManager'
      },
      {
        path: 'reviewerOrderManager',
        component: resolve => require(['@/views/reviewer/reviewerFbUserOrder'], resolve),
        name: '客人订单',
        authority: 'reviewerOrderManager'
      },
      {
        path: 'reviewerFbProductManager',
        component: resolve => require(['@/views/reviewer/reviewerFbProduct'], resolve),
        name: '运营产品列表',
        authority: 'reviewerFbProductManager'
      },
      {
        path: 'userInfoManager',
        component: resolve => require(['@/views/userCenter/userInfoManager'], resolve),
        name: '用户信息管理',
        authority: 'userInfoManager'
      },
      {
        path: 'mailUseRecord',
        component: resolve => require(['@/views/userCenter/mailUseRecord'], resolve),
        name: '邮箱使用记录',
        authority: 'mailUseRecord'
      },
      {
        path: 'blackList',
        component: resolve => require(['@/views/userCenter/blackList'], resolve),
        name: '测评黑名单',
        authority: 'blackList'
      }
    ]
  },
  {
    path: '/promoteManager',
    component: Layout,
    name: '测评推广',
    icon: 'setting',
    authority: 'promoteManager',
    redirect: '/promoteManager/accountCompanyManager',
    children: [
      {
        path: 'accountCompanyManager',
        component: resolve => require(['@/views/promote/financeAccountCompany'], resolve),
        authority: 'accountCompanyManager'
      },
      {
        path: 'productLimitManager',
        component: resolve => require(['@/views/promote/promoteProductLimit'], resolve),
        authority: 'productLimitManager'
      },
      {
        path: 'rateFeeLimitManager',
        component: resolve => require(['@/views/promote/promoteRateFeeLimit'], resolve),
        authority: 'rateFeeLimitManager'
      },
      {
        path: 'reimbersementManager',
        component: resolve => require(['@/views/promote/promoteReimbersement'], resolve),
        authority: 'reimbersementManager'
      },
      {
        path: 'reimbersementProcessManager',
        component: resolve => require(['@/views/promote/promoteReimbersementProcess'], resolve),
        authority: 'reimbersementProcessManager'
      },
      {
        path: 'reimbersementProcessAppManager',
        component: resolve => require(['@/views/promote/promoteReimbersementProcessApp'], resolve),
        authority: 'reimbersementProcessAppManager',
        name: '测评审批-移动端'
      },
      {
        path: 'cashierPaymentManager',
        component: resolve => require(['@/views/promote/cashierPayment'], resolve),
        authority: 'cashierPaymentManager',
        name: '出纳付款'
      },
      {
        path: 'promoteSpanReportManager',
        component: resolve => require(['@/views/promote/promoteSpanReport'], resolve),
        authority: 'promoteSpanReportManager',
        name: '测评费用报表'
      },
      {
        path: 'paymentDetailManager',
        component: resolve => require(['@/views/promote/promotePaymentDetail'], resolve),
        authority: 'paymentDetailManager',
        name: '单据付款明细'
      },
      {
        path: 'promoteItemDetailManager',
        component: resolve => require(['@/views/promote/promoteItemDetail'], resolve),
        authority: 'promoteItemDetailManager',
        name: '其他上报'
      },
      {
        path: 'itemProcessManager',
        component: resolve => require(['@/views/promote/promoteItemProcess'], resolve),
        authority: 'itemProcessManager',
        name: '其他上报流程'
      },
      {
        path: 'itemProcessAppManager',
        component: resolve => require(['@/views/promote/promoteItemProcessApp'], resolve),
        authority: 'itemProcessAppManager',
        name: '其他上报审批-移动端'
      },
      {
        path: 'itemPaymentManager',
        component: resolve => require(['@/views/promote/promoteItemPayment'], resolve),
        authority: 'itemPaymentManager',
        name: '其他上报付款'
      },
      {
        path: 'itemPaymentDetailManager',
        component: resolve => require(['@/views/promote/promoteItemPaymentDetail'], resolve),
        authority: 'itemPaymentDetailManager',
        name: '其他上报付款明细'
      },
      {
        path: 'other-report',
        component: resolve => require(['@/views/promote/other-report'], resolve),
        authority: 'other-report',
        name: '其他上报新增'
      }
    ]
  },
  {
    path: '/orderManager',
    component: Layout,
    name: '订单中心',
    authority: 'orderManager',
    children: [
      {
        path: 'soOrderManager',
        component: resolve => require(['@/views/order/soOrder'], resolve),
        authority: 'soOrderManager',
        name: '订单列表'
      },
      {
        path: 'orderOpManager',
        component: resolve => require(['@/views/order/soOrderOpLog'], resolve),
        authority: 'orderOpManager',
        name: '订单上传'
      },
      {
        path: 'paymentOpManager',
        component: resolve => require(['@/views/order/paymentReportOp'], resolve),
        authority: 'paymentOpManager',
        name: 'payment上传'
      }
    ]
  },
  {
    path: '/sysManager',
    component: Layout,
    name: '基础信息',
    authority: 'sysManager',
    children: [
      {
        path: 'inventory',
        component: resolve => require(['@/views/inventory/list'], resolve),
        authority: 'inventory',
        name: '库存快照'
      },
      {
        path: 'sysProductManager',
        component: resolve => require(['@/views/sys/sysProduct'], resolve),
        authority: 'sysProductManager',
        name: '产品与对应人员关系表'
      },
      {
        path: 'giftcardDepotManager',
        component: resolve => require(['@/views/sys/sysGiftcardDepot'], resolve),
        name: 'BLN Gift Card仓库',
        authority: 'giftcardDepotManager'
      },
      {
        path: 'blnGiftWarehouseManager',
        component: resolve => require(['@/views/sys/sysGiftWarehouse'], resolve),
        name: 'BLN实物礼物列表',
        authority: 'blnGiftWarehouseManager'
      },
      {
        path: 'skuBindCategoryManager',
        component: resolve => require(['@/views/order/skuBindCategory'], resolve),
        authority: 'skuBindCategoryManager',
        name: '品类与SKU绑定'
      },
      {
        path: 'categoryReasonManager',
        component: resolve => require(['@/views/order/categoryBindReason'], resolve),
        authority: 'categoryReasonManager',
        name: '品类与原因绑定'
      },
      {
        path: 'blnOrderAddressManager',
        component: resolve => require(['@/views/reviewer/bln/blnOrderAddress'], resolve),
        name: '百灵鸟订单地址',
        authority: 'blnOrderAddressManager'
      },
      {
        path: 'giftContrastManager',
        component: resolve => require(['@/views/sys/giftContrast'], resolve),
        name: '百灵鸟订单地址',
        authority: 'giftContrastManager'
      },
      {
        path: 'productCost',
        component: resolve => require(['@/views/sys/product-cost'], resolve),
        name: '产品成本',
        authority: 'productCost'
      },
      {
        path: 'exchangeRate',
        component: () => import('@/views/promoteCostReport/exchange-rate'),
        name: 'exchangeRate',
        authority: 'exchangeRate',
        meta: {
          title: '汇率'
        }
      }
    ]
  },
  {
    path: '/giftInfoManager',
    component: Layout,
    name: '礼物信息管理',
    icon: 'setting',
    authority: 'giftInfoManager',
    children: [
      {
        path: 'giftCardInfoManager',
        component: resolve => require(['@/views/sys/giftCardInfo'], resolve),
        name: '礼品卡图片',
        authority: 'giftCardInfoManager'
      },
      {
        path: 'objectGiftInfoManager',
        component: resolve => require(['@/views/sys/inventory/giftInfo'], resolve),
        name: '实物礼物信息',
        authority: 'objectGiftInfoManager'
      },
      {
        path: 'skuPromoteGiftInfo',
        component: resolve => require(['@/views/sys/inventory/skuPromoteGiftInfo'], resolve),
        name: 'SKU推广礼物列表',
        authority: 'skuPromoteGiftInfo'
      }
    ]
  },
  {
    path: '/giftInventoryManager',
    component: Layout,
    name: '礼物库存管理',
    authority: 'giftInventoryManager',
    children: [
      {
        path: 'giftInventoryQuery',
        component: resolve => require(['@/views/sys/inventory/giftInventoryQuery'], resolve),
        name: '所有渠道库存查询',
        authority: 'giftInventoryQuery'
      },
      {
        path: 'giftWarehouseManager',
        component: resolve => require(['@/views/sys/inventory/giftWarehouse'], resolve),
        name: '礼物入库',
        authority: 'giftWarehouseManager'
      },
      {
        path: 'giftWarehouseOutManager',
        component: resolve => require(['@/views/sys/inventory/giftWarehouseOut'], resolve),
        name: '礼物出库',
        authority: 'giftWarehouseOutManager'
      }
    ]
  },
  {
    path: '/customerServiceManager',
    component: Layout,
    name: '客服管理',
    authority: 'customerServiceManager',
    children: [
      {
        path: 'warranty-extension-order',
        component: () => import('@/views/warranty/order'),
        name: '官网延保工单',
        authority: 'warranty-extension-order'
      },
      {
        path: 'giftGrantManager',
        component: resolve => require(['@/views/customerService/giftGrant'], resolve),
        name: '礼物发放',
        authority: 'giftGrantManager'
      },
      {
        path: 'giftGrantApprovalManager',
        component: resolve => require(['@/views/customerService/giftGrantApproval'], resolve),
        name: '礼品卡发放审批',
        authority: 'giftGrantApprovalManager'
      },
      {
        path: 'customerServiceOrderManager',
        component: resolve => require(['@/views/order/customerServiceOrder'], resolve),
        authority: 'customerServiceOrderManager',
        name: '客诉/差评/测评'
      },
      {
        path: 'walmartReview',
        component: resolve => require(['@/views/customerService/walmartReview'], resolve),
        authority: 'walmartReview',
        name: '沃尔玛评价'
      },
      {
        path: 'review',
        component: resolve => require(['@/views/customerService/review'], resolve),
        authority: 'review',
        name: '客服评价管理'
      }
    ]
  }
];
