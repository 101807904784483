import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      let msgMethod =
        params.url === '/api/reviewer/blnOperationInfo/checkSku' || res.resCode === -2
          ? Message.warning
          : Message.error;
      msgMethod(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};
// 公司sku下拉列表
export function getPermissionSku(params = {}) {
  return fetch({
    url: '/api/base/baseSysProductDetail/queryPermissionSku',
    method: 'get',
    params
  });
}
// 新增弹窗-公司sku下拉列表
export function getQueryPermissionSkuByPlatform(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/queryPermissionSkuByPlatform',
    method: 'get',
    params
  });
}
// 运营下拉列表
export function getOperationUser(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getOperationUser',
    method: 'get',
    params
  });
}
// 产品状态
export function getProductStatusEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/ProductStatusEnum',
    method: 'get',
    params
  });
}
// 新增或修改运营信息登记
export function postInsertOrUpdateInfo(data = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/insertOrUpdateInfo',
    method: 'post',
    data
  });
}
// 运营信息查询
export function getOperationInfoList(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/operationInfoList',
    method: 'get',
    params
  });
}
// 平台sku下拉
export function getMallSkuList(params = {}) {
  return fetch({
    url: '/api/base/baseSkuMallMapping/queryMallSkuList',
    method: 'get',
    params
  });
}
// 平台类型下拉列表
export function getPlatform(params = {}) {
  return fetch({
    url: '/api/base/baseGroupPlatform/getPlatform',
    method: 'get',
    params
  });
}
// sku基础信息
export function getSkuBySkuCode(params = {}) {
  return fetch({
    url: '/api/base/baseSysProductDetail/getSkuBySkuCode',
    method: 'get',
    params
  });
}
// sku基础信息-品类
export function getQueryBaseSku(params = {}) {
  return fetch({
    url: '/api/base/baseSku/queryBaseSku',
    method: 'get',
    params
  });
}
// 礼品卡币别列表
export function getCurrencyInInventoryDetail(params = {}) {
  return fetch({
    url: '/api/sys/dic/getCurrencyInInventoryDetail',
    method: 'get',
    params
  });
}
// 获取运营信息详情
export function getblnOperationInfo(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getInfo',
    method: 'get',
    params
  });
}
// 获取平台sku信息
export function getListByOperationId(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationPlatformSku/listByOperationId',
    method: 'get',
    params
  });
}
// 刷新周销售数量
export function getRefreshVolume(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/refreshVolume',
    method: 'get',
    params
  });
}

// 获取渠道
export function getWarehouseName(params = {}) {
  return fetch({
    url: '/api/base/baseWarehouse/queryWarehouseName',
    method: 'get',
    params
  });
}
// 校验sku是否存在
export function getCheckSku(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/checkSku',
    method: 'get',
    params
  });
}
// 查询礼物卡片表存在的平台类型
export function getCurrencyInGiftCard(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getCurrencyInGiftCard',
    method: 'get',
    params
  });
}
// 获取海外经办部门
export function getOverseasDepartmentEnum(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/getOverseasDepartment',
    method: 'get',
    params
  });
}

// 运营信息-获取sku信息
export function getSkuInfoBySkuAndPlatform(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getSkuInfoBySkuAndPlatform',
    method: 'get',
    params
  });
}

// 运营信息-获取平台sku和产品状态
export function getQueryMallSkuAndProductStatus(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/queryMallSkuAndProductStatus',
    method: 'get',
    params
  });
}

// 运营信息-查询国家
export function getOperationExistCountry(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/operationExistCountry',
    method: 'get',
    params
  });
}

// 运营信息-查询界面下拉sku
export function getQueryExistPermissionSku(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/queryExistPermissionSku',
    method: 'get',
    params
  });
}

// 运营信息-asin下拉
export function getQueryExistAsin(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/queryExistAsin',
    method: 'get',
    params
  });
}
