import { getAllSkuStatus, getAllChannelShow } from '@/api/sale/index';
import { getSupplyPlanLogistics, getWarehouseTransportWay, getSupplyChannelCategory } from '@/api/supply/index';
import { getAllMarketPlatform } from 'api/customerService/giftGrant';

let allSkuStatusPromise = null;
let allPlatNamePromise = null;
let allChannelShowPromise = null;
let supplyPlanLogisticsPromise = null;
let warehouseTransportWayPromise = null;
let supplyChannelCategoryPromise = null;

const common = {
  actions: {
    fetchAllSkuStatus({}, refresh) {
      if (allSkuStatusPromise && !refresh) {
        return allSkuStatusPromise;
      } else {
        allSkuStatusPromise = new Promise((resolve, reject) => {
          getAllSkuStatus()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return allSkuStatusPromise;
      }
    },
    fetchAllPlatName({}, refresh) {
      if (allPlatNamePromise && !refresh) {
        return allPlatNamePromise;
      } else {
        allPlatNamePromise = new Promise((resolve, reject) => {
          getAllMarketPlatform()
            .then(res => {
              resolve(res.data.map(({ nameCode }) => nameCode));
            })
            .catch(err => {
              reject(err);
            });
        });
        return allPlatNamePromise;
      }
    },
    fetchAllChannelShow({}, refresh) {
      if (allChannelShowPromise && !refresh) {
        return allChannelShowPromise;
      } else {
        allChannelShowPromise = new Promise((resolve, reject) => {
          getAllChannelShow()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return allChannelShowPromise;
      }
    },
    fetchSupplyPlanLogistics({}, refresh) {
      if (supplyPlanLogisticsPromise && !refresh) {
        return supplyPlanLogisticsPromise;
      } else {
        supplyPlanLogisticsPromise = new Promise((resolve, reject) => {
          getSupplyPlanLogistics()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return supplyPlanLogisticsPromise;
      }
    },

    fetchWarehouseTransportWay({}, refresh) {
      if (warehouseTransportWayPromise && !refresh) {
        return warehouseTransportWayPromise;
      } else {
        warehouseTransportWayPromise = new Promise((resolve, reject) => {
          getWarehouseTransportWay()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return warehouseTransportWayPromise;
      }
    },

    fetchSupplyChannelCategory({}, refresh) {
      if (supplyChannelCategoryPromise && !refresh) {
        return supplyChannelCategoryPromise;
      } else {
        supplyChannelCategoryPromise = new Promise((resolve, reject) => {
          getSupplyChannelCategory()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return supplyChannelCategoryPromise;
      }
    }
  }
};

export default common;
