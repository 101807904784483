import fetch from 'utils/http';
import { getToken } from 'utils/auth';
import axios from 'axios';
import qs from 'qs';
// 客户列表-分页查询
export function getB2bCustomerInfoPage(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInfo/page',
    method: 'get',
    params
  });
}

export function getB2bInvoicePage(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInfo/invoicePage',
    method: 'get',
    params
  });
}

// 客户列表-新增或更新
export function postAddOrUpdateCustomer(data = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInfo/addOrUpdateCustomer',
    method: 'post',
    data
  });
}

// 客户列表-查询发票列表
export function getListInvoiceByCustomerId(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInvoice/listInvoiceByCustomerId',
    method: 'get',
    params
  });
}

// 客户列表-新增或编辑发票
export function postAddOrUpdateInvoicer(data = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInvoice/addOrUpdateInvoicer',
    method: 'post',
    data
  });
}

// 客户列表-根据订单号查询订单信息
export function getOrdersByOrderNo(params = {}) {
  return fetch({
    url: '/api/base/nextopOmsOrder/getOrdersByOrderNo',
    method: 'get',
    params
  });
}

// 客户列表-发票详情
export function getInvoiceDetail(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInvoice/detail',
    method: 'get',
    params
  });
}

// 客户列表-校验添加发票
export function postCheckInvoiceOrder(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInvoice/checkInvoiceOrder',
    method: 'get',
    params
  });
}

// 客户列表-删除发票
export function postDeleteInvoiceOrder({ id }) {
  return fetch({
    url: `/api/operate/b2bCustomerInvoice/delete/${id}`,
    method: 'post'
  });
}

// 查询公司主体及银行
export function getAllCompanyBank(params = {}) {
  return fetch({
    url: '/api/operate/b2bCompanyBank/getAllCompanyBank',
    method: 'get',
    params
  });
}

// 回款信息列表查询
export function getQueryBackPaymentList(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/queryBackPaymentList',
    method: 'get',
    params
  });
}

// 回款信息-保存或提交回款信息
export function postAddOrUpdBackPayment(data = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/addOrUpdBackPayment',
    method: 'post',
    data
  });
}

// 回款信息-列表 提交按钮接口
export function getSubmitBackPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/submitBackPayment',
    method: 'get',
    params
  });
}

// 回款信息-删除
export function getDeleteBackPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/deleteBackPayment',
    method: 'get',
    params
  });
}

// 回款信息-单条查询回款信息和订单信息
export function getBackPaymentById(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/getBackPaymentById',
    method: 'get',
    params
  });
}

// 回款-付款-客户名称查询发票及回款信息
export function getCustomerAndInvoice(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInfo/getCustomerAndInvoice',
    method: 'get',
    params
  });
}

// 回款信息-确认回款信息
export function postConfirmBackPayment(data = {}) {
  return fetch({
    url: '/api/operate/b2bBackPaymentFinance/confirmBackPayment',
    method: 'post',
    data
  });
}

// 回款信息-页签数据条数
export function getBackPaymentTabNum(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/getBackPaymentTabNum',
    method: 'get',
    params
  });
}

// 回款信息-导出
export function getAddB2bBackPaymentExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/addB2bBackPaymentExcelTask',
    method: 'get',
    params
  });
}

// 付款申请-查询付款类目
export function getAllCategoryByNow(params = {}) {
  return fetch({
    url: '/api/operate/b2bCategoryLimit/getAllCategoryByNow',
    method: 'get',
    params
  });
}

// 付款申请-付款单列表
export function getB2bPaymentPage(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/page',
    method: 'get',
    params
  });
}

// 付款申请-保存提交
export function postSaveOrSubmitPayment(data = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/saveOrSubmitPayment',
    method: 'post',
    data
  });
}

// 付款-根据发票号查询订单数据
export function getOrderInfoByInvoiceNum(data = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/getOrderInfoByInvoiceNum',
    method: 'post',
    data
  });
}

// 付款申请-付款单详情
export function getB2bPaymentDetail({ id }) {
  return fetch({
    url: `/api/operate/b2bPayment/detail/${id}`,
    method: 'get'
  });
}

// 付款申请-列表提交
export function getSubmitPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/submitPayment',
    method: 'get',
    params
  });
}

// 付款申请-删除
export function getDeletePayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/deletePayment',
    method: 'get',
    params
  });
}

// 付款申请-审核
export function postAuditPayment(data = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/auditPayment',
    method: 'post',
    data
  });
}

// 付款申请-查询审核日志
export function getLogByPaymentId(params = {}) {
  return fetch({
    url: '/api/operate/b2bPaymentLog/getLogByPaymentId',
    method: 'get',
    params
  });
}

// 付款申请-确认付款
export function postConfirmPayment(data = {}) {
  return fetch({
    url: '/api/operate/b2bPaymentFinance/confirmPayment',
    method: 'post',
    data
  });
}

// 付款申请-获取所有状态
export function getPaymentAllStatus(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/getPaymentAllStatus',
    method: 'get',
    params
  });
}

// 付款申请-页签数据条数
export function getPaymentTabNum(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/getPaymentTabNum',
    method: 'get',
    params
  });
}

// 获取最新汇率
export function getLastRate(params = {}) {
  return fetch({
    url: '/api/base/baseExchangeRate/getLastRate',
    method: 'get',
    params
  });
}

// 付款申请 - 付款单列表_导出
export function getExportPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/export/payment',
    method: 'get',
    params
  });
}

// 附件下载
export function getBatchDownLoad(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/operate/b2bPayment/batchDownLoad', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() }
      })
      .then(res => {
        let { data } = res;

        if (data) {
          let fileName = '';
          let contentDisposition = res.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(
              res.headers['content-disposition'].split(';')[1].replace(/[".zip|filename="]/g, ''),
              'UTF-8'
            );
          }
          resolve({ data, fileName });
        } else {
          reject();
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 发货申请-列表
export function getQueryDeliverGoodsList(params = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/queryDeliverGoodsList',
    method: 'get',
    params
  });
}

// 发货申请-查询订单状态
export function getOrderStatus(params = {}) {
  return fetch({
    url: '/api/base/nextopOmsOrder/getOrderStatus',
    method: 'get',
    params
  });
}

// 发货申请-查询所有发票号
export function getInvoiceNumberList(params = {}) {
  return fetch({
    url: '/api/operate/b2bCustomerInvoice/getInvoiceNumberList',
    method: 'get',
    params
  });
}

// 发货申请-申请发货
export function postAddDeliverGoods(data = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/addDeliverGoods',
    method: 'post',
    data
  });
}

// 发货申请-确认发货
export function postConfirmDeliver(data = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/confirmDeliverGoods',
    method: 'post',
    data
  });
}

// 发货申请-审核
export function postAuditDeliverGoods(data = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/auditDeliverGoods',
    method: 'post',
    data
  });
}

// 发货申请-关联回款
export function postAddBackPaymentFlow(data = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/addBackPaymentFlow',
    method: 'post',
    data
  });
}

// 发货申请-查询日志和审核人
export function getDeliverGoodsLog(params = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoodsLog/getDeliverGoodsLog',
    method: 'get',
    params
  });
}

// 发货申请导出
export function getAddB2bDeliverExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/addB2bDeliverExcelTask',
    method: 'get',
    params
  });
}

// 根据订单号查询订单数据
export function getOrderInfoByOrderNo(params = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/getOrderInfoByOrderNo',
    method: 'get',
    params
  });
}

// 根据订单号查询回款流水号和订单信息
export function getOrderBackPaymentInfo(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/getOrderBackPaymentInfo',
    method: 'get',
    params
  });
}

// 发货申请详情
export function getDeliverGoodsDetail(params = {}) {
  return fetch({
    url: '/api/operate/b2bDeliverGoods/deliverGoodsDetail',
    method: 'get',
    params
  });
}

// 查询财务确认回款内容
export function getFinanceBackPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPaymentFinance/getFinanceBackPayment',
    method: 'get',
    params
  });
}

// 查询统计期初期末数据看板
export function getPeriodStatistics(params = {}) {
  return fetch({
    url: '/api/operate/b2bPeriodCustomer/periodStatistics',
    method: 'get',
    params
  });
}

// 查询本期发生总额订单信息
export function getOrderInfoByDeliverTime(params = {}) {
  return fetch({
    url: '/api/base/nextopOmsOrder/orderInfoByDeliverTime',
    method: 'get',
    params
  });
}

// 查询本期回款总额回款信息
export function getPeriodBackPayment(params = {}) {
  return fetch({
    url: '/api/operate/b2bBackPayment/getPeriodBackPayment',
    method: 'get',
    params
  });
}

// 期初期末数据列表查询
export function getPeriodListPage(params = {}) {
  return fetch({
    url: '/api/operate/b2bPeriodCustomer/periodListPage',
    method: 'get',
    params
  });
}

// 期初期末-查询订单详情
export function getPeriodOrderInfo(params = {}) {
  return fetch({
    url: '/api/operate/b2bPeriodCustomer/getPeriodOrderInfo',
    method: 'get',
    params
  });
}

// 期初期末导出
export function getAddB2bPeriodExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/b2bPeriodCustomer/addB2bPeriodExcelTask',
    method: 'get',
    params
  });
}

// 付款申请-保存附件
export function postSaveB2bPaymentAttach(data = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/saveB2bPaymentAttach',
    method: 'post',
    data
  });
}

// 付款申请-删除附件
export function postDelB2bPaymentAttach(data = {}) {
  return fetch({
    url: '/api/operate/b2bPayment/delB2bPaymentAttach',
    method: 'post',
    data
  });
}

// 根据订单号更新/下载明峰订单
export function getSyncOrdersByOrderNo(params = {}) {
  return fetch({
    url: '/api/data-sync/ntDataDownload/syncOrdersByOrderNo',
    method: 'get',
    params
  });
}
