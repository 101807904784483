import {
  getKolRequireStatusList,
  getAllChangeUser,
  getAllKolChannel,
  getMarketPlatInfo,
  getSkuInfo,
  getSelectAllCurrency,
  getLogisticsList,
  getAllKolCategory
} from '@/api/kol/index';

let kolRequireStatusListPromise = null;
let allChangeUserPromise = null;
let allKolChannelPromise = null;
let marketPlatInfoPromise = null;
let skuInfoPromise = null;
let selectAllCurrencyPromise = null;
let logisticsListPromise = null;
let queryKolPoolListPromise = null;

const common = {
  actions: {
    // 获取kol所有状态
    fetchKolRequireStatusList({}, refresh) {
      if (kolRequireStatusListPromise && !refresh) {
        return kolRequireStatusListPromise;
      } else {
        kolRequireStatusListPromise = new Promise((resolve, reject) => {
          getKolRequireStatusList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return kolRequireStatusListPromise;
      }
    },
    // 获取所有负责人
    fetchAllChangeUser({}, refresh) {
      if (allChangeUserPromise && !refresh) {
        return allChangeUserPromise;
      } else {
        allChangeUserPromise = new Promise((resolve, reject) => {
          getAllChangeUser()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return allChangeUserPromise;
      }
    },
    // 获取所有的kol渠道
    fetchAllKolChannel({}, refresh) {
      if (allKolChannelPromise && !refresh) {
        return allKolChannelPromise;
      } else {
        allKolChannelPromise = new Promise((resolve, reject) => {
          getAllKolChannel()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return allKolChannelPromise;
      }
    },
    // 获取平台和店铺
    fetchMarketPlatInfo({}, refresh) {
      if (marketPlatInfoPromise && !refresh) {
        return marketPlatInfoPromise;
      } else {
        marketPlatInfoPromise = new Promise((resolve, reject) => {
          getMarketPlatInfo()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return marketPlatInfoPromise;
      }
    },
    // 获取公司sku数据
    fetchSkuInfo({}, refresh) {
      if (skuInfoPromise && !refresh) {
        return skuInfoPromise;
      } else {
        skuInfoPromise = new Promise((resolve, reject) => {
          getSkuInfo()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return skuInfoPromise;
      }
    },
    // 币别
    fetchSelectAllCurrency({}, refresh) {
      if (selectAllCurrencyPromise && !refresh) {
        return selectAllCurrencyPromise;
      } else {
        selectAllCurrencyPromise = new Promise((resolve, reject) => {
          getSelectAllCurrency()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return selectAllCurrencyPromise;
      }
    },

    // 物流商
    fetchLogisticsList({}, refresh) {
      if (logisticsListPromise && !refresh) {
        return logisticsListPromise;
      } else {
        logisticsListPromise = new Promise((resolve, reject) => {
          getLogisticsList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return logisticsListPromise;
      }
    },

    // 类别
    fetchAllKolCategory({}, refresh) {
      if (queryKolPoolListPromise && !refresh) {
        return queryKolPoolListPromise;
      } else {
        queryKolPoolListPromise = new Promise((resolve, reject) => {
          getAllKolCategory()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return queryKolPoolListPromise;
      }
    }
  }
};

export default common;
