import fetch from 'utils/http';
// 查询产品成本管理列表
export function getCostManageProductList(params = {}) {
  return fetch({
    url: '/api/operate/costManageProduct/costManageProductList',
    method: 'get',
    params
  });
}

// 查询历史价格
export function getProductCostOrigin(params = {}) {
  return fetch({
    url: '/api/operate/costManageProduct/getProductCostOrigin',
    method: 'get',
    params
  });
}

// 导出-产品成本
export function getAddProductCostExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/costManageProduct/addProductCostExcelTask',
    method: 'get',
    params
  });
}

// 更新运营成本比例
export function postUpdateProductCost(data = {}) {
  return fetch({
    url: '/api/operate/costManageProduct/updateProductCost',
    method: 'post',
    data
  });
}

// 查询三级品类
export function getDeepThreeCategoryList(params = {}) {
  return fetch({
    url: '/api/base/nextopCategory/deepThreeCategoryList',
    method: 'get',
    params
  });
}

// 查询品线人员关联列表
export function getCategoryUserList(params = {}) {
  return fetch({
    url: '/api/operate/costManageCategory/categoryUserList',
    method: 'get',
    params
  });
}

// 根据品类获取人员信息（单条查询 下拉可用）
export function getUserByCategory(params = {}) {
  return fetch({
    url: '/api/operate/costManageCategory/getUserByCategory',
    method: 'get',
    params
  });
}

// 新增和更新品线人员关联
export function postAddOrUpdCategoryUser(data = {}) {
  return fetch({
    url: '/api/operate/costManageCategory/addOrUpdCategoryUser',
    method: 'post',
    data
  });
}

// 查询所有启动用户信息
export function getAllStartUser(params = {}) {
  return fetch({
    url: '/api/base/baseUser/getAllStartUser',
    method: 'get',
    params
  });
}
