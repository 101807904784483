import { getDeepThreeCategoryList, getAllStartUser } from '@/api/cost/index';

let deepThreeCategoryListPromise = null;
let getAllStartUserPromise = null;
const common = {
  actions: {
    fetchDeepThreeCategory({}, refresh) {
      if (deepThreeCategoryListPromise && !refresh) {
        return deepThreeCategoryListPromise;
      } else {
        deepThreeCategoryListPromise = new Promise((resolve, reject) => {
          getDeepThreeCategoryList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return deepThreeCategoryListPromise;
      }
    },
    fetchAllStartUser({}, refresh) {
      if (getAllStartUserPromise && !refresh) {
        return getAllStartUserPromise;
      } else {
        getAllStartUserPromise = new Promise((resolve, reject) => {
          getAllStartUser()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return getAllStartUserPromise;
      }
    }
  }
};

export default common;
