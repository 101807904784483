import fetch from 'utils/http';
import request from 'utils/fetch';

// 获取销售预测sku列表
export function getQueryForecastSkuList(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/queryForecastSkuList',
    method: 'get',
    params
  });
}

// 全部-销售预测列表
export function getQuerySaleForecastList(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/querySaleForecastList',
    method: 'get',
    params
  });
}

// 公司SKU-编辑保存
export function postSaveForecastSku(data = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/saveForecastSku',
    method: 'post',
    data
  });
}
// 获取所有的产品状态
export function getAllSkuStatus(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/getAllSkuStatus',
    method: 'get',
    params
  });
}

// 获取所有的平台
export function getAllPlatName(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/getAllPlatName',
    method: 'get',
    params
  });
}

// 公司sku-查询审核日志
export function getForecastSkuLog(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSkuLog/getForecastSkuLog',
    method: 'get',
    params
  });
}

// 公司sku-审核
export function postAuditForecastSku(data = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/auditForecastSku',
    method: 'post',
    data
  });
}

// 公司sku-查询审核人
export function getQueryForecastSkuAuditer(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/queryForecastSkuAuditer',
    method: 'get',
    params
  });
}

// 编辑销售预测-查询上一周的预计销量
export function getQueryBeforeWeekSale(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/queryBeforeWeekSale',
    method: 'get',
    params
  });
}

// 编辑-保存和提交销售预测数据
export function postSaveOrSubmitSaleForecast(data = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/saveOrSubmitSaleForecast',
    method: 'post',
    data
  });
}

// 查询替代sku
export function getQueryNotSaleSku(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/queryNotSaleSku',
    method: 'get',
    params
  });
}

// 批量提交销售预测
export function getSubmitBatchSaleForecast(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/submitBatchSaleForecast',
    method: 'get',
    params
  });
}

// 批量撤回销售预测
export function getWithdrawBatchForecast(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/withdrawBatchForecast',
    method: 'get',
    params
  });
}

// 批量审核-销售预测
export function getAuditSaleForecast(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/auditSaleForecast',
    method: 'get',
    params
  });
}

// 查看趋势图
export function getQueryTrendChart(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/queryTrendChart',
    method: 'get',
    params
  });
}

// 销售预测导出
export function getAddForecastExcelTask(params = {}) {
  return fetch({
    url: '/api/promote/saleForecast/addForecastExcelTask',
    method: 'get',
    params
  });
}

// 查询历史销售数据
export function getQueryHistoryForecast(params = {}) {
  return fetch({
    url: '/api/promote/saleForecastVersion/queryHistoryForecast',
    method: 'get',
    params
  });
}

// 销售计划维护店铺列表
export function getQueryBaseStoreList(params = {}) {
  return fetch({
    url: '/api/base/baseStore/queryBaseStoreList',
    method: 'get',
    params
  });
}

// 获取店铺所有渠道
export function getAllChannelShow(params = {}) {
  return fetch({
    url: '/api/base/baseStore/getAllChannelShow',
    method: 'get',
    params
  });
}

// 更新店铺信息
export function postUpdateBaseStore(data = {}) {
  return fetch({
    url: '/api/base/baseStore/updateBaseStore',
    method: 'post',
    data
  });
}

// 新增销售预测sku
export function postAddForecastSku(data = {}) {
  return fetch({
    url: '/api/promote/saleForecastSku/addForecastSku',
    method: 'post',
    data
  });
}

// SKU生成销售计划
export function getGenerateSaleForecast(params = {}) {
  return request({
    url: '/api/promote/saleForecastSku/generateSaleForecast',
    method: 'get',
    params
  }).then(res => {
    if (res.resCode === 200 || res.resCode === -1) {
      return res;
    } else {
      Message.error('系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
}

// 导出销售预测数据模板
export function getExportSaleTemplate(params = {}) {
  return fetch({
    url: '/api/promote/saleForecast/exportSaleTemplate',
    method: 'get',
    params
  });
}

// 导出其他数据模板
export function getExportIndicatorsTemplate(params = {}) {
  return fetch({
    url: '/api/promote/saleForecast/exportIndicatorsTemplate',
    method: 'get',
    params
  });
}

// 销量售价预测导入
export function postImportForecastSale(data = {}) {
  return fetch({
    url: '/api/promote/saleForecast/importForecastSale',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}

// 其他指标数据导入
export function postImportForecastIndicators(data = {}) {
  return fetch({
    url: '/api/promote/saleForecast/importForecastIndicators',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  });
}
