import fetch from 'utils/fetch';

export function getWorkOrderReport (query) {
  return fetch({
    url: '/api/reviewer/blnSaleRequire/getWorkOrderReport',
    method: 'post',
    data: query
  });
}

export function getWorkOrderReportChart (query) {
  return fetch({
    url: '/api/reviewer/blnSaleRequire/getWorkOrderReportChart',
    method: 'post',
    data: query
  });
}

export function getDeptByGroup (query) {
  return fetch({
    url: '/api/sys/dic/getDeptByGroup',
    method: 'get',
    params: query
  });
}