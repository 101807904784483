import { getCountryList, selectBaseCountry } from '@/api/common/sysRemoteDic';
import { getDeptByGroup } from '@/api/reviewer/bln/blnSimpleReport';
import { getCategoryByGroupName } from '@/api/report/operation';

import {
  getProductStatusEnum,
  getPlatform,
  getCurrencyInInventoryDetail,
  getWarehouseName,
  getCurrencyInGiftCard,
  getOverseasDepartmentEnum
} from '@/api/lark/operation';
import { getPlanOrderStatusEnum, getPlatformEnum, getAllPlatform } from '@/api/lark/plan';
import { getSendLetterStatusEnum } from '@/api/lark/overseas';
import { getPlanOrderDetailStatusEnum, getOrderReviewerStatusEnum } from '@/api/lark/follow';
import { getAccounts } from '@/api/mailCenter/mailManager';
import { getCountryCurrencyList } from 'api/common/sysRemoteDic';
import { setSessionStorage, getSessionStorage } from '@/utils/auth';

let countryListPromise = null;
let deptByGroupPromise = null;
let categoryListPromise = null;
let productStatusEnumPromise = null;
let platformPromise = null;
let currencyInInventoryPromise = null;
let warehouseNamePromise = null;
let planOrderStatusEnumPromise = null;
let planPlatformPromise = null;
let sendLetterStatusEnumPromise = null;
let platformEnumPromise = null;
let currencyInGiftCardPromise = null;
let planOrderDetailStatusEnumPromise = null;
let orderReviewerStatusEnumPromise = null;
let countryCurrencyListPromise = null;
let overseasDepartmentEnumPromise = null;
let accountsPromise = null;
let currencyListPromise = null;

const common = {
  state: {
    countryList: [],
    deptByGroup: {},
    categoryList: [],
    productStatusEnum: [],
    platform: [],
    inventory: [],
    warehouseName: [],
    sendListQuery: {},
    highLevelForm: {},
    jumpTag: 0,
    dropText: '',
    dropValue: '',
    userList: [],
    promoteQuery: {},
    flag: '',
    otherPromoteType: getSessionStorage('promoteType')
  },
  mutations: {
    SET_PROMOTE_TYPE: (state, type) => {
      if (!state.otherPromoteType) {
        state.otherPromoteType = type;
        setSessionStorage('promoteType', type);
      } else {
        state.otherPromoteType = type;
        setSessionStorage('promoteType', type);
      }
    },
    SET_PROMOTE_QUERYFLAG: (state, flag) => {
      state.flag = flag;
    },
    SET_PROMOTE_QUERY: (state, listQuery) => {
      state.promoteQuery = listQuery;
    },
    SET_FUZZY_USERLIST: (state, userList) => {
      state.userList = userList;
    },
    SET_JUMP_DROPTEXT: (state, dropText) => {
      state.dropText = dropText;
    },
    SET_JUMP_DROPVALUE: (state, dropValue) => {
      state.dropValue = dropValue;
    },
    SET_JUMP_TAG: (state, jumpTag) => {
      state.jumpTag = jumpTag;
    },
    SET_SENDLIST_QUERY: (state, payload) => {
      state.sendListQuery = payload;
    },
    SET_HIGHLEVEL_QUERY: (state, payload) => {
      state.highLevelForm = payload;
    },
    SET_COUNTRY_LIST: (state, countryList) => {
      state.countryList = countryList;
    },
    SET_DEPT_BY_GROUP: (state, deptByGroup) => {
      state.deptByGroup = deptByGroup.reduce((acc, next) => {
        const { dicName, dicValue } = next;
        acc[dicName] ? acc[dicName].push(dicValue) : (acc[dicName] = [dicValue]);
        return acc;
      }, {});
    },
    SET_CATEGORY_LIST: (state, categoryList) => {
      state.categoryList = categoryList;
    },
    SET_PRODUCT_STATUS_ENUM: (state, productStatusEnum) => {
      state.productStatusEnum = productStatusEnum;
    },
    SET_PLAT_FORM: (state, platform) => {
      state.platform = platform;
    },
    SET_CURRENCY_IN_INVENTORY: (state, inventory) => {
      state.inventory = inventory;
    },
    SET_WAREHOUSE_NAME: (state, warehouseName) => {
      state.warehouseName = warehouseName;
    }
  },
  getters: {
    countryList: state => state.countryList,
    deptByGroup: state => state.deptByGroup,
    categoryList: state => state.categoryList,
    productStatusEnum: state => state.productStatusEnum,
    platform: state => state.platform,
    inventory: state => state.inventory,
    warehouseName: state => state.warehouseName
  },
  actions: {
    // 拉取国家码数据
    fetchCountryList({ commit }, refresh) {
      if (countryListPromise && !refresh) {
        return countryListPromise;
      } else {
        countryListPromise = new Promise((resolve, reject) => {
          getCountryList()
            .then(res => {
              commit('SET_COUNTRY_LIST', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return countryListPromise;
      }
    },
    // 拉取事业群数据
    fetchDeptByGroup({ commit, state }, refresh) {
      if (deptByGroupPromise && !refresh) {
        return deptByGroupPromise;
      } else {
        deptByGroupPromise = new Promise((resolve, reject) => {
          getDeptByGroup()
            .then(res => {
              commit('SET_DEPT_BY_GROUP', res.data);
              resolve(state.deptByGroup);
            })
            .catch(err => {
              reject(err);
            });
        });
        return deptByGroupPromise;
      }
    },
    // 拉取品类数据
    fetchCategoryList({ commit }, refresh) {
      if (categoryListPromise && !refresh) {
        return categoryListPromise;
      } else {
        categoryListPromise = new Promise((resolve, reject) => {
          getCategoryByGroupName()
            .then(res => {
              commit('SET_CATEGORY_LIST', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return categoryListPromise;
      }
    },
    // 拉取产品状态
    fetchProductStatusEnum({ commit }, refresh) {
      if (productStatusEnumPromise && !refresh) {
        return productStatusEnumPromise;
      } else {
        productStatusEnumPromise = new Promise((resolve, reject) => {
          getProductStatusEnum()
            .then(res => {
              commit('SET_PRODUCT_STATUS_ENUM', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });

        return productStatusEnumPromise;
      }
    },
    // 拉取平台类型
    fetchplatform({ commit }, refresh) {
      if (platformPromise && !refresh) {
        return platformPromise;
      } else {
        platformPromise = new Promise((resolve, reject) => {
          getPlatform()
            .then(res => {
              commit('SET_PLAT_FORM', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return platformPromise;
      }
    },
    // 拉取币别
    fetchCurrencyInInventoryDetail({ commit }, refresh) {
      if (currencyInInventoryPromise && !refresh) {
        return currencyInInventoryPromise;
      } else {
        currencyInInventoryPromise = new Promise((resolve, reject) => {
          getCurrencyInInventoryDetail()
            .then(res => {
              commit('SET_CURRENCY_IN_INVENTORY', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return currencyInInventoryPromise;
      }
    },
    // 拉取渠道
    fetchcChannelList({ commit }, refresh) {
      if (warehouseNamePromise && !refresh) {
        return warehouseNamePromise;
      } else {
        warehouseNamePromise = new Promise((resolve, reject) => {
          getWarehouseName()
            .then(res => {
              commit('SET_WAREHOUSE_NAME', res.data);
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return warehouseNamePromise;
      }
    },
    // 拉取工单状态
    fetchPlanOrderStatusEnum() {
      if (planOrderStatusEnumPromise) {
        return planOrderStatusEnumPromise;
      } else {
        planOrderStatusEnumPromise = new Promise((resolve, reject) => {
          getPlanOrderStatusEnum()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return planOrderStatusEnumPromise;
    },
    // 拉取全部平台类型
    fetchPlatformEnum() {
      if (platformEnumPromise) {
        return platformEnumPromise;
      } else {
        platformEnumPromise = new Promise((resolve, reject) => {
          getPlatformEnum()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return platformEnumPromise;
    },
    // 拉取工单计划平台类型
    fetchPlanPlatform({ dispatch }, refresh) {
      if (planPlatformPromise && !refresh) {
        return planPlatformPromise;
      } else {
        planPlatformPromise = new Promise((resolve, reject) => {
          Promise.all([dispatch('fetchPlatformEnum'), getAllPlatform()])
            .then(([platformData, allPlatformData]) => {
              const platformSet = new Set(allPlatformData.data);
              const data = platformData.filter(({ value }) => platformSet.has(value));
              resolve(data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return planPlatformPromise;
    },
    // 拉取工单状态
    fetchSendLetterStatusEnum() {
      if (sendLetterStatusEnumPromise) {
        return sendLetterStatusEnumPromise;
      } else {
        sendLetterStatusEnumPromise = new Promise((resolve, reject) => {
          getSendLetterStatusEnum()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return sendLetterStatusEnumPromise;
    },
    // 拉取礼品卡平台类型
    fetchCurrencyInGiftCard() {
      if (currencyInGiftCardPromise) {
        return currencyInGiftCardPromise;
      } else {
        currencyInGiftCardPromise = new Promise((resolve, reject) => {
          getCurrencyInGiftCard()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return currencyInGiftCardPromise;
    },
    // 拉取工单状态枚举
    fetchPlanOrderDetailStatusEnum() {
      if (planOrderDetailStatusEnumPromise) {
        return planOrderDetailStatusEnumPromise;
      } else {
        planOrderDetailStatusEnumPromise = new Promise((resolve, reject) => {
          getPlanOrderDetailStatusEnum()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return planOrderDetailStatusEnumPromise;
    },
    // 拉取评价状态
    fetchOrderReviewerStatusEnum() {
      if (orderReviewerStatusEnumPromise) {
        return orderReviewerStatusEnumPromise;
      } else {
        orderReviewerStatusEnumPromise = new Promise((resolve, reject) => {
          getOrderReviewerStatusEnum()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return orderReviewerStatusEnumPromise;
    },
    // 拉取国家对应的币别
    fetchCountryCurrencyList() {
      if (countryCurrencyListPromise) {
        return countryCurrencyListPromise;
      } else {
        countryCurrencyListPromise = new Promise((resolve, reject) => {
          selectBaseCountry()
            .then(res => {
              const data = Object.values(res.data).map(({ dicName: dicValue, dicValue: dicName }) => ({
                dicValue,
                dicName
              }));
              resolve(data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return countryCurrencyListPromise;
      }
    },
    // 拉取海外仓映射
    fetchOverseasDepartmentEnum() {
      if (overseasDepartmentEnumPromise) {
        return overseasDepartmentEnumPromise;
      } else {
        overseasDepartmentEnumPromise = new Promise((resolve, reject) => {
          getOverseasDepartmentEnum()
            .then(res => {
              resolve(res.data.map(value => Object.assign(value, { caption: value.name })));
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return overseasDepartmentEnumPromise;
    },
    fetchAccount(store, refresh) {
      if (accountsPromise && !refresh) {
        return accountsPromise;
      } else {
        accountsPromise = new Promise((resolve, reject) => {
          getAccounts({ status: 1, page: 1, limit: 100 })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
      }
      return accountsPromise;
    },

    fetchCurrencyList() {
      if (currencyListPromise) {
        return currencyListPromise;
      } else {
        currencyListPromise = new Promise((resolve, reject) => {
          getCountryCurrencyList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return currencyListPromise;
      }
    }
  }
};

export default common;
