const mail = {
  state: {
    draftId: ''
  },
  mutations: {
    SET_DRAFT_ID: (state, draftId) => {
      state.draftId = draftId;
    }
  },
  getters: {
    draftId: state => state.draftId
  }
};

export default mail;
