import fetch from 'utils/fetch';
//礼品发放页面查询
export function getAllProductGift(query) {
  return fetch({
    url: '/api/sys/csGiftDetail/getAllProductGift',
    method: 'get',
    params: query
  });
}
//实物礼物地址导出
export function exportProductGiftData(query) {
  return fetch({
    url: '/api/sys/csGiftReport/insertTaskData',
    method: 'get',
    params: query
  });
}
//查询单条待发放实物礼物信息
export function getReadySendGiftInfo(query) {
  return fetch({
    url: '/api/sys/csGiftDetail/getReadySendGiftInfo',
    method: 'get',
    params: query
  });
}
export function updProductGiftTrackInfo(obj) {
  return fetch({
    url: '/api/sys/csGiftDetail/updProductGiftTrackInfo',
    method: 'post',
    data: obj
  });
}
//获取所有平台类型
export function getAllMarketPlatform(query) {
  return fetch({
    url: '/api/sys/sysMarketPlatform/getAllMarketPlatform',
    method: 'get',
    params: query
  });
}
//实物礼物地址导入已发放的实物礼物数据
export function importProductGift(obj) {
  return fetch({
    url: '/api/sys/csGiftDetail/importProductGift',
    method: 'post',
    data: obj
  });
}
//礼物数据修改同步工单跟进的状态
export function syncTrackStatusByGift(obj) {
  return fetch({
    url: '/api/reviewer/blnWorkOrderTrack/syncTrackStatusByGift',
    method: 'post',
    data: obj
  });
}
//礼物发放：退回礼物
export function giftReturnBack(query) {
  return fetch({
    url: '/api/sys/csGiftDetail/giftReturnBack',
    method: 'get',
    params: query
  });
}
//实物礼物地址导入已发放的实物礼物数据
export function fillTailData(obj) {
  return fetch({
    url: '/api/sys/csGiftDetail/fillTailData',
    method: 'post',
    data: obj
  });
}
//获取币别
export function getCountryCurrencyList() {
  return fetch({
    url: '/api/base/baseCountry/selectAllCurrency',
    method: 'get'
  });
}
