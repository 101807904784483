import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/lark-manager',
    component: Layout,
    name: 'larkManager',
    authority: 'lark-manager',
    meta: {
      title: '百灵鸟通用版'
    },
    children: [
      {
        path: '/lark-manager/operation-info',
        component: () => import('@/views/lark/operation-info'),
        name: 'larkOperationInfo',
        authority: 'operation-info',
        meta: {
          title: '运营信息登记'
        }
      },
      {
        path: '/lark-manager/work-order-plan',
        component: () => import('@/views/lark/work-order-plan'),
        name: 'larkWorkOrderPlan',
        authority: 'work-order-plan',
        meta: {
          title: '工单计划'
        }
      },
      {
        path: '/lark-manager/overseas-handler',
        component: () => import('@/views/lark/overseas-handler'),
        name: 'larkOverseasHandler',
        authority: 'overseas-handler',
        meta: {
          title: '海外仓操作'
        }
      },
      {
        path: '/lark-manager/work-order-follow',
        component: () => import('@/views/lark/work-order-follow'),
        name: 'larkWorkOrderFollow',
        authority: 'work-order-follow',
        meta: {
          title: '工单跟进'
        }
      }
    ]
  },

  {
    path: '/lark-report',
    component: Layout,
    name: 'larkReport',
    authority: 'lark-report',
    meta: {
      title: '报表查询'
    },
    children: [
      {
        path: '/lark-report/review-statistics',
        component: () => import('@/views/lark/report/review-statistics'),
        name: 'larkReviewStatistics',
        authority: 'review-statistics',
        meta: {
          title: '产品回评率统计'
        }
      }
    ]
  }
];
