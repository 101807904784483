import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/hive',
    component: Layout,
    name: '蜂巢项目',
    authority: 'hive',
    redirect: '/hive/info-list',
    children: [
      {
        path: 'info-list',
        component: () => import('@/views/hive/info-list'),
        name: '基础信息配置',
        authority: 'info-list'
      },
      {
        path: 'code-list',
        component: () => import('@/views/hive/code-list'),
        name: '产品二维码',
        authority: 'code-list'
      },
      {
        path: 'client-list',
        component: () => import('@/views/hive/client-list'),
        name: '客户信息',
        authority: 'client-list'
      }
    ]
  }
];
