import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      Message.error(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};

// 工单-平台类型
export function getPlatformEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/PlatformEnum',
    method: 'get',
    params
  });
}

// 工单-运营登记表存在的平台类型集合
export function getAllPlatform(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getAllPlatform',
    method: 'get',
    params
  });
}

// 工单-公司sku下拉列表
export function getSkuByPlatform(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getSkuByPlatform',
    method: 'get',
    params
  });
}

// 工单-运营登记信息
export function getInfoByPlatformAndSku(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOperationInfo/getInfoByPlatformAndSku',
    method: 'get',
    params
  });
}

// 工单-状态
export function getPlanOrderStatusEnum(params = {}) {
  return fetch({
    url: '/api/base/enum/PlanOrderStatusEnum',
    method: 'get',
    params
  });
}

// 工单-查询
export function getPlanOrderList(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/planOrderList',
    method: 'get',
    params
  });
}

// 工单-查询已选地址
export function getQueryByPlanOrderId(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/queryByPlanOrderId',
    method: 'get',
    params
  });
}

// 工单-查询可选地址
export function getQueryUsableOrderAddress(params = {}) {
  return fetch({
    url: '/api/reviewer/blnOrderAddress/queryUsableOrderAddress',
    method: 'get',
    params
  });
}

// 工单-获取计划工单及其子信息
export function getBlnPlanOrderInfo(params = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/getPlanOrder',
    method: 'get',
    params
  });
}

// 工单-保存选用地址
export function postSaveAddress(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/saveAddress',
    method: 'post',
    data
  });
}

// 工单-提交工单地址
export function postCommitPlanOrder(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrderDetail/commitAddress',
    method: 'post',
    data
  });
}

// 工单-新增修改计划工单
export function postInsertOrUpdatePlanOrder(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/insertOrUpdatePlanOrder',
    method: 'post',
    data
  });
}

// 工单-提交工单
export function putCommitPlanOrder(data = {}) {
  return fetch({
    url: '/api/reviewer/blnPlanOrder/commitPlanOrder',
    method: 'put',
    data
  });
}

// 工单-审核
export function putAuditPlanOrder(id, data = {}) {
  return fetch({
    url: `/api/reviewer/blnPlanOrder/${id}/auditPlanOrder`,
    method: 'put',
    data
  });
}

// 工单-退回
export function putCommitAddressReset(id, data = {}) {
  return fetch({
    url: `/api/reviewer/blnPlanOrder/${id}/commitAddressReset`,
    method: 'put',
    data
  });
}

// 工单-可选地址-删除
export function putUpdateStatusById(id, data = {}) {
  return fetch({
    url: `/api/reviewer/blnOrderAddress/${id}/updateStatusById`,
    method: 'put',
    data
  });
}

// 工单-计划工单-删除
export function deletePlanOrder(id, data = {}) {
  return fetch({
    url: `/api/reviewer/blnPlanOrder/${id}/deletePlanOrder`,
    method: 'delete',
    data
  });
}
