import fetch from 'utils/http';

// 店铺列表查询
export function getQueryStoreList(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceStoreList/queryStoreList',
    method: 'get',
    params
  });
}

// 编辑店铺信息
export function postUpdStoreListById(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceStoreList/updStoreListById',
    method: 'post',
    data
  });
}

// 任务配置查询
export function getQueryKeywordTaskList(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceKeywordTask/queryKeywordTaskList',
    method: 'get',
    params
  });
}

// 任务配置-删除任务
export function getDelKeywordTask(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceKeywordTask/delKeywordTask',
    method: 'get',
    params
  });
}

// 任务配置-新增和更新
export function postAddOrUpdKeywordTask(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceKeywordTask/addOrUpdKeywordTask',
    method: 'post',
    data
  });
}

// 业绩通知列表
export function getQueryNotificationTaskList(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/queryNotificationTaskList',
    method: 'get',
    params
  });
}

// 生成任务
export function postGenerateTask(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/generateTask',
    method: 'post',
    data
  });
}

// 标记无需处理
export function getSignNotDeal(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/signNotDeal',
    method: 'get',
    params
  });
}

// 分配负责人
export function postAssignChargeUser(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/assignChargeUser',
    method: 'post',
    data
  });
}

// 获取所有品牌
export function getAllBrand(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/getAllBrand',
    method: 'get',
    params
  });
}

// 任务处理保存
export function postSaveTaskInfo(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/saveTaskInfo',
    method: 'post',
    data
  });
}

// 查询跟进记录和附件反馈内容
export function getTrackAndFeedbackInfo(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/getTrackAndFeedbackInfo',
    method: 'get',
    params
  });
}

// 结果反馈
export function postResultFeedback(data = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotificationTask/resultFeedback',
    method: 'post',
    data
  });
}

// 获取店铺详情
export function getStoreInfo(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceNotification/getStoreInfo',
    method: 'get',
    params
  });
}

// 获取用户所属soc部门
export function getHrmDepartmentInfo(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceStoreList/getHrmDepartmentInfo',
    method: 'get',
    params
  });
}

// 查询店铺概况
export function getQueryStoreGeneralSituation(params = {}) {
  return fetch({
    url: '/api/reviewer/performanceStoreList/queryStoreGeneralSituation',
    method: 'get',
    params
  });
}
