export default [
  {
    path: 'product-cost-list',
    component: () => import('@/views/cost/product-cost-list'),
    name: '产品成本管理',
    authority: 'product-cost-list'
  },
  {
    path: 'category-list',
    component: () => import('@/views/cost/category-list'),
    name: '品线人员关联',
    authority: 'category-list'
  }
];
