import fetch from 'utils/http';

// 基础信息配置 - 列表查询
export function getHoneycombBasePage(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombBase/honeycombBasePage',
    method: 'get',
    params
  });
}

// 基础信息配置 - 查询客服邮箱
export function getServiceMailboxList(params = {}) {
  return fetch({
    url: '/api/warranty-extension/mailServer/serviceMailboxList',
    method: 'get',
    params
  });
}

// 查询国家和语言
export function getQueryCountryLanguage(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombBase/queryCountryLanguage',
    method: 'get',
    params
  });
}

// 基础信息配置 - 单条查询数据
export function getHoneycombBaseById(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombBase/getHoneycombBaseById',
    method: 'get',
    params
  });
}

// 基础信息配置 - 添加和修改配置
export function postAddOrUpdHoneycombBase(data = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombBase/addOrUpdHoneycombBase',
    method: 'post',
    data
  });
}

// 产品二维码 - 查询sku和品牌品线信息
export function getQuerySkuInfo(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrCode/querySkuInfo',
    method: 'get',
    params
  });
}

// 产品二维码 - 查询配置模版
export function getQueryBaseTemplate(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrCode/queryBaseTemplate',
    method: 'get',
    params
  });
}

// 产品二维码 - 列表查询
export function getHoneycombQrPage(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrCode/honeycombQrPage',
    method: 'get',
    params
  });
}

// 产品二维码-新增和更新产品二维码信息
export function postAddOrUpdProductQrCode(data = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrCode/addOrUpdProductQrCode',
    method: 'post',
    data
  });
}

// 产品二维码 - 生成二维码链接
export function getGenerateQrCode(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrDetail/generateQrCode',
    method: 'get',
    params
  });
}

// 产品二维码 - 查看二维码链接
export function getProductQrDetailPage(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrDetail/productQrDetailPage',
    method: 'get',
    params
  });
}

// 产品二维码 - 标记完成
export function getSignQrCodeFinish(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrDetail/signQrCodeFinish',
    method: 'get',
    params
  });
}

// 产品二维码-导出
export function getAddQrCodeTask(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombProductQrCode/addQrCodeTask',
    method: 'get',
    params
  });
}

// 客户信息列表
export function getHoneycombCustomerPage(params = {}) {
  return fetch({
    url: '/api/warranty-extension/honeycombCustomerInfo/honeycombCustomerPage',
    method: 'get',
    params
  });
}

//
// export function (params = {}) {
//   return fetch({
//     url: '',
//     method: 'get',
//     params
//   });
// }

//
// export function (data = {}) {
//   return fetch({
//     url: '',
//     method: 'post',
//     data
//   });
// }
