import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
const tipsArray = [
  {
    url: '/api/tool/fastDfsClient/upload',
    tips: { 501: '文件内容为空，请重新上传附件', 500: '上传失败' }
  }
];

const getTips = ({ url, code }) => {
  const tipsObj = tipsArray.find(value => url === value.url);
  return tipsObj ? tipsObj.tips[code] || '' : '';
};

let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      const tips = getTips({ url: params.url, code: res.resCode });
      Message.error(tips || (res ? res.resDes : '系统后台异常，请联CRM技术支持！'));
      return Promise.reject(res);
    }
  });
};

export default fetch;
