<template>
  <el-breadcrumb class="app-levelbar" separator="/">
    <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
      <span v-if="item.redirect === 'noredirect' || index == levelList.length - 1" class="no-redirect">{{
        item.name
      }}</span>
      <router-link v-else :to="item.redirect || item.path">{{ item.name }}</router-link>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import Bus from './bus';
export default {
  created() {
    this.getBreadcrumb();
  },
  data() {
    return {
      levelList: null,
      checkedName: undefined
    };
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.name);
      const first = matched[0];
      if (first && (first.name !== '首页' || first.path !== '')) {
        matched = [{ name: '首页', path: '/' }].concat(matched);
      }
      this.levelList = matched;
      let arr = matched;
      this.checkedName = [...arr].pop().name;
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },

    checkedName() {
      Bus.$emit('checkedName', this.checkedName);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" slot-scope>
.app-levelbar.el-breadcrumb {
  // display: inline-block;
  float: left;
  font-size: 14px;
  line-height: 50px;
  margin-left: 10px;
  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
