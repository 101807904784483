<template>
  <div class="menu-wrapper">
    <template v-for="item in routes">
      <el-submenu :index="item.title" :key="item.name" v-if="item.children && item.children.length > 0">
        <template slot="title">
          <icon-svg v-if="item.icon" :icon-class="item.icon"></icon-svg>
          <span>{{ item.title }}</span>
        </template>
        <template v-for="child in item.children">
          <template v-if="!hiddenItem.includes(child.code)">
            <sidebar-item
              class="nest-menu"
              v-if="child.children && child.children.length > 0"
              :routes="[child]"
              :key="child.name"
            >
            </sidebar-item>
            <a
              target="_blank"
              v-if="child.href != null && child.href.indexOf('http') == 0"
              :href="child.href"
              :key="child.name"
            >
              <el-menu-item :index="'/' + item.code + '/' + child.code">
                <icon-svg v-if="child.icon" :icon-class="child.icon"></icon-svg>
                <span>{{ child.title }}</span>
              </el-menu-item>
            </a>
            <router-link
              v-if="child.href != null && child.href.indexOf('http') != 0 && child.type != 'dirt'"
              :to="'/' + item.code + '/' + child.code"
              :key="child.name"
            >
              <el-menu-item :index="'/' + item.code + '/' + child.code">
                <icon-svg v-if="child.icon" :icon-class="child.icon"></icon-svg>
                <span>{{ child.title }}</span>
              </el-menu-item>
            </router-link>
          </template>
        </template>
      </el-submenu>
      <template v-else>
        <a
          target="_blank"
          v-if="item.href != null && item.href.indexOf('http') == 0"
          :href="item.href"
          :key="item.name"
        >
          <el-menu-item :class="isCollapse ? 'demo' : ''" :index="'/' + item.code">
            <icon-svg v-if="item.icon" :icon-class="item.icon"></icon-svg>
            <span>{{ item.title }}</span>
          </el-menu-item>
        </a>
        <router-link
          v-if="item.href != null && item.href.indexOf('http') != 0 && item.type != 'dirt'"
          :to="'/' + item.href"
          :key="item.name"
        >
          <el-menu-item :class="isCollapse ? 'demo2' : ''" :index="'/' + item.href">
            <icon-svg v-if="item.icon" :icon-class="item.icon"></icon-svg>
            <span v-if="item.title.indexOf('CRM') != 0">{{ item.title }}</span>
          </el-menu-item>
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SidebarItem',
  data() {
    return {
      isOpened: undefined,
      hiddenItem: [
        'mails-detail',
        'pullRecord',
        'add-template',
        'other-report',
        'kol-detail',
        'kol-info-detail',
        'edit-forecast',
        'history-forecast',
        'store-detail',
        'period-detail',
        'budget-detail',
        'supply-detail',
        'reimbersementProcessAppManager',
        'itemProcessAppManager'
      ]
    };
  },
  props: {
    routes: {
      type: Array
    }
  },
  computed: {
    ...mapGetters(['sidebar']),

    isCollapse() {
      return !this.sidebar.opened;
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" slot-scope>
.demo,
.demo2 {
  padding: 0 !important;
  .svg-icon {
    margin: 0 0 0 10px !important;
  }
}
.demo {
  overflow: hidden;
  .svg-icon {
    margin-right: 16px !important;
  }
}
</style>
