import fetch from 'utils/fetch';

// 查询用户的平台类型权限
export function getPlatformByUser(query) {
  return fetch({
    url: '/api/base/baseGroupPlatform/getPlatformByUser',
    method: 'get',
    params: query
  });
}
// 根据平台类型查询对应站点
export function getAccountCompanyByPlatform(query) {
  return fetch({
    url: '/api/promote/financeAccountCompany/getAccountCompanyByPlatform',
    method: 'get',
    params: query
  });
}

// 获取项目分类模板和对应的项目
export function getAllItemClassIfy(query) {
  return fetch({
    url: '/api/promote/promoteItemClassify/getAllItemClassIfy',
    method: 'get',
    params: query
  });
}

// 其他上报新增和修改
export function addOrUpdItemDetail(obj) {
  return fetch({
    url: '/api/promote/promoteItemDetail/addOrUpdItemDetail',
    method: 'post',
    data: obj
  });
}

// 其他上报单条查询
export function getItemDetailById(query) {
  return fetch({
    url: '/api/promote/promoteItemDetail/getItemDetailById',
    method: 'get',
    params: query
  });
}

// 其他上报批量导入
export function importItemDetail(obj) {
  return fetch({
    url: '/api/promote/promoteItemDetail/importItemDetail',
    method: 'post',
    data: obj
  });
}
// 保存并提交其他上报
export function submitAddOrUpdItemDetail(obj) {
  return fetch({
    url: '/api/promote/promoteItemDetail/submitAddOrUpdItemDetail',
    method: 'post',
    data: obj
  });
}

// 获取汇率表所有币别
export function getExchangeRateCurrency(query) {
  return fetch({
    url: '/api/base/baseExchangeRate/getExchangeRateCurrency',
    method: 'get',
    params: query
  });
}

export function getItemProcessById(query) {
  return fetch({
    url: '/api/promote/promoteItemProcess/getItemProcessById',
    method: 'get',
    params: query
  });
}
