import { getAllBrand, getHrmDepartmentInfo } from '@/api/account/index';
import { getUserPage } from 'api/admin/user/user';
let allBrandPromise = null;
let userPagePromise = null;
let hrmDepartmentInfoPromise = null;
const account = {
  actions: {
    // 获取所有品牌
    fetchAllBrand({}, refresh) {
      if (allBrandPromise && !refresh) {
        return allBrandPromise;
      } else {
        allBrandPromise = new Promise((resolve, reject) => {
          getAllBrand()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return allBrandPromise;
      }
    },
    // 获取所有钉钉用户
    fetchUserPage({}, refresh) {
      if (userPagePromise && !refresh) {
        return userPagePromise;
      } else {
        userPagePromise = new Promise((resolve, reject) => {
          getUserPage({ page: 1, limit: 2000 })
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return userPagePromise;
      }
    },
    // 获取用户所属soc部门
    fetchHrmDepartmentInfo({}, refresh) {
      if (hrmDepartmentInfoPromise && !refresh) {
        return hrmDepartmentInfoPromise;
      } else {
        hrmDepartmentInfoPromise = new Promise((resolve, reject) => {
          getHrmDepartmentInfo()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return hrmDepartmentInfoPromise;
      }
    }
  }
};

export default account;
