import Layout from '@/views/layout/Layout';
import b2b from './b2b';
import cost from './cost';

export default [
  {
    path: '/operationManager',
    component: Layout,
    name: '运营管理',
    authority: 'operationManager',
    children: [
      {
        path: 'keyword-manager',
        component: () => import('@/views/operateManager/keyword-manager'),
        name: '关键词管理',
        authority: 'keyword-manager'
      },
      {
        path: 'promote-log',
        component: () => import('@/views/operateManager/promote-log'),
        name: '推广日志',
        authority: 'promote-log'
      },
      ...b2b,
      ...cost
    ]
  },
  {
    path: '/reportQuery',
    component: Layout,
    name: '运营报表查询',
    authority: 'reportQuery',
    children: [
      {
        path: 'averageWeekStar',
        component: () => import('@/views/report/weekStar/index'),
        name: '平均周星级',
        authority: 'averageWeekStar'
      },
      {
        path: 'starChange',
        component: () => import('@/views/report/starChange'),
        name: '星级变动明细表',
        authority: 'starChange'
      },
      {
        path: 'weekChange',
        component: () => import('@/views/report/weekChange'),
        name: '每周星级变更表',
        authority: 'weekChange'
      },
      {
        path: 'ratingQuery',
        component: () => import('@/views/report/rating'),
        name: 'rating查询',
        authority: 'ratingQuery'
      }
    ]
  }
];
