import fetch from 'utils/http';
import { getToken } from 'utils/auth';
import axios from 'axios';
import qs from 'qs';
// 物流费用-获取所有国家
export function getLogisticsCountry(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/getLogisticsCountry',
    method: 'get',
    params
  });
}

// 物流费用-获取所有运输方式
export function getLogisticsTransportMode(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/getLogisticsTransportMode',
    method: 'get',
    params
  });
}

// 物流费用-列表查询
export function getLogisticsCostPage(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/logisticsCostPage',
    method: 'get',
    params
  });
}

// 导出物流费用数据模板
export function getExportLogisticsCostTemplate(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/operate/saleBudgetLogisticsCost/exportLogisticsCostTemplate', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() },
        paramsSerializer: params => {
          let p = Object.keys(params).reduce((acc, next) => {
            if (params[next] !== null) {
              acc[next] = params[next];
            }
            return acc;
          }, {});
          return qs.stringify(p, { arrayFormat: 'repeat' });
        }
      })
      .then(res => {
        let { data } = res;
        let fileName = '物流费用';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 导入物流费用数据
export function postImportLogisticsCost(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/importLogisticsCost',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}

// 年度预算列表查询
export function getBudgetPage(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/budgetPage',
    method: 'get',
    params
  });
}

// 年度预算-下载模板
export function getExportSaleBudgetTemplate(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/operate/saleBudget/exportSaleBudgetTemplate', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() },
        paramsSerializer: params => {
          let p = Object.keys(params).reduce((acc, next) => {
            if (params[next] !== null) {
              acc[next] = params[next];
            }
            return acc;
          }, {});
          return qs.stringify(p, { arrayFormat: 'repeat' });
        }
      })
      .then(res => {
        let { data } = res;
        let fileName = '年度预算';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 年度预算-上传文件
export function postImportSaleBudget(data = {}) {
  return fetch({
    url: '/api/operate/saleBudget/importSaleBudget',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}

// 年度预算-提交
export function getSubmitSaleBudget(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/submitSaleBudget',
    method: 'get',
    params
  });
}

// 年度预算-审核
export function postAuditSalBudget(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/auditSalBudget',
    method: 'post',
    data
  });
}

// 年度预算-查询审核信息
export function getAuditLogInfo(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLog/getAuditLogInfo',
    method: 'get',
    params
  });
}

// 年度预算-获取页签数量
export function getSaleBudgetTabNum(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/getSaleBudgetTabNum',
    method: 'get',
    params
  });
}

// 年度预算-作废
export function postInvalidSaleBudget(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/invalidSaleBudget',
    method: 'post',
    data
  });
}

// 年度预算-查看详情
export function getSaleBudgetDetail(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/getSaleBudgetDetail',
    method: 'get',
    params
  });
}

// 年度预算-查询历史数据
export function getHistorySaleBudget(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/getHistorySaleBudget',
    method: 'get',
    params
  });
}

// 年度预算-导出
export function getAddSaleBudgetExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/addSaleBudgetExcelTask',
    method: 'get',
    params
  });
}

// SPU管理-全部SPU列表
export function getListBudgetSpus(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpu/listBudgetSpus',
    method: 'get',
    params
  });
}

// SPU管理-新增SPU
export function postSaveSpu(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/saveSpu',
    method: 'post',
    data
  });
}

// SPU管理-分配SPU
export function postAssignSpu(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/assignSpu',
    method: 'post',
    data
  });
}

// SPU管理-SPU分配列表
export function getListBudgetAssignSpus(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/listBudgetAssignSpus',
    method: 'get',
    params
  });
}

// SPU管理-更换运营
export function postChangeOperator(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/changeOperator',
    method: 'post',
    data
  });
}

// SPU管理-SPU替换
export function postReplaceSpu(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/replaceSpu',
    method: 'post',
    data
  });
}

// SPU管理-SPU变更审核
export function postAuditReplaceSpu(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/auditReplaceSpu',
    method: 'post',
    data
  });
}
// SPU管理-查询审核信息
export function getSpuAuditInfo(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/getSpuAuditInfo',
    method: 'get',
    params
  });
}

// SPU管理-生成年度预算
export function postInitSaleBudget(data = {}) {
  return fetch({
    url: '/api/operate/saleBudget/initSaleBudget',
    method: 'post',
    data
  });
}

// 获取年度预算所有的状态
export function getSaleBudgetStatus(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/getSaleBudgetStatus',
    method: 'get',
    params
  });
}

// 删除年度预算数据
export function postDeleteSaleBudget(data = {}) {
  return fetch({
    url: '/api/operate/saleBudget/deleteSaleBudget',
    method: 'post',
    data
  });
}

// SPU管理-编辑SPU
export function postAuditSpu(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetSpuAssign/auditSpu',
    method: 'post',
    data
  });
}

// 查询品牌列表
export function getBrandList(params = {}) {
  return fetch({
    url: '/api/base/baseSku/getBrandList',
    method: 'get',
    params
  });
}

// 年度预算-查询页签统计
export function getBudgetTabStatistics(params = {}) {
  return fetch({
    url: '/api/operate/saleBudget/getBudgetTabStatistics',
    method: 'get',
    params
  });
}

// 预算总览
export function getBudgetStatistics(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/budgetStatistics',
    method: 'get',
    params
  });
}

// 预算总览查看详情
export function getBudgetStatisticsDetail(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/budgetStatisticsDetail',
    method: 'get',
    params
  });
}

// 预算总览-审核查询
export function getBudgetAuditDetail(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/budgetAuditDetail',
    method: 'get',
    params
  });
}

// 预算总览-审核
export function postStatisticsAudit(data = {}) {
  return fetch({
    url: '/api/operate/saleBudgetDetail/statisticsAudit',
    method: 'post',
    data
  });
}
