import { getLogisticsCountry, getLogisticsTransportMode, getBrandList, getSaleBudgetStatus } from '@/api/budget/index';

let logisticsCountryPromise = null;
let logisticsTransportModePromise = null;
let brandListPromise = null;
let saleBudgetStatusPromise = null;
const common = {
  actions: {
    fetchLogisticsCountry({}, refresh) {
      if (logisticsCountryPromise && !refresh) {
        return logisticsCountryPromise;
      } else {
        logisticsCountryPromise = new Promise((resolve, reject) => {
          getLogisticsCountry()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return logisticsCountryPromise;
      }
    },
    fetchLogisticsTransportMode({}, refresh) {
      if (logisticsTransportModePromise && !refresh) {
        return logisticsTransportModePromise;
      } else {
        logisticsTransportModePromise = new Promise((resolve, reject) => {
          getLogisticsTransportMode()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return logisticsTransportModePromise;
      }
    },
    fetchBrandList({}, refresh) {
      if (brandListPromise && !refresh) {
        return brandListPromise;
      } else {
        brandListPromise = new Promise((resolve, reject) => {
          getBrandList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return brandListPromise;
      }
    },
    fetchSaleBudgetStatus({}, refresh) {
      if (saleBudgetStatusPromise && !refresh) {
        return saleBudgetStatusPromise;
      } else {
        saleBudgetStatusPromise = new Promise((resolve, reject) => {
          getSaleBudgetStatus()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return saleBudgetStatusPromise;
      }
    }
  }
};

export default common;
