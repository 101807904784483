import fetch from 'utils/fetch';

export function loginByEmail(username, password,veritycode) {
  const data = {
    username,
    password,
    veritycode
  };
  return fetch({
    url: '/api/auth/jwt/token',
    method: 'post',
    data
  });
}

export function logout(token) {
  return fetch({
    url: '/api/auth/jwt/invalid',
    method: 'get',
    params: { token }
  });
}

export function getInfo(token) {
  return fetch({
    url: '/api/admin/user/front/info',
    method: 'get',
    params: { token }
  });
}

export function getMenus(token) {
  return fetch({
    url: '/api/admin/user/front/menus',
    method: 'get',
    params: { token }
  });
}

export function getAllMenus() {
  return fetch({
    url: '/api/admin/user/front/menu/all',
    method: 'get'
  });
}

export function getGroups(token) {
  return fetch({
    url: '/api/admin/group/front/listGroupsByName',
    method: 'get',
    params: { token }
  });
}

export function forgotPassword(obj) {
  return fetch({
    url: '/api/admin/user/forgetPwd',
    method: 'post',
    data: obj
  })
}

export function captcha(query) {
  return fetch({
    url: '/api/auth/jwt/captcha',
    method: 'get',
    responseType:'blob',
    params: query
  })
}

export function getForgotPwdCaptcha(query) {
  return fetch({
    url: '/api/admin/user/captcha',
    method: 'get',
    responseType:'blob',
    params: query
  })
}


