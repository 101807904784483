import fetch from 'utils/http';
import { getToken } from 'utils/auth';
import axios from 'axios';
import qs from 'qs';
// kol列表查询
export function getQueryKolRequireList(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/queryKolRequireList',
    method: 'get',
    params
  });
}

// 获取kol所有状态
export function getKolRequireStatusList(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/getKolRequireStatusList',
    method: 'get',
    params
  });
}

// 获取所有负责人
export function getAllChangeUser(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/getAllChangeUser',
    method: 'get',
    params
  });
}

// 获取所有的kol渠道
export function getAllKolChannel(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/getAllKolChannel',
    method: 'get',
    params
  });
}

// 获取平台和店铺
export function getMarketPlatInfo(params = {}) {
  return fetch({
    url: '/api/promote/kolRequireProduct/getMarketPlatInfo',
    method: 'get',
    params
  });
}

// 获取公司sku数据
export function getSkuInfo(params = {}) {
  return fetch({
    url: '/api/base/baseSku/getBaseSkuList',
    method: 'get',
    params
  });
}

// 获取审核日志
export function getLogByRequireId(params = {}) {
  return fetch({
    url: '/api/promote/kolRequireAuitLog/getLogByRequireId',
    method: 'get',
    params
  });
}

// kol新增和编辑
export function postAddOrUpdKolRequire(data = {}) {
  return fetch({
    url: '/api/promote/kolRequire/addOrUpdKolRequire',
    method: 'post',
    data
  });
}

// kol单条查询
export function getKolRequireById(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/getKolRequireById',
    method: 'get',
    params
  });
}

// kol需求效果追踪页面查询
export function getRedsList(params = {}) {
  return fetch({
    url: '/api/promote/kolRequireReds/getRedsList',
    method: 'get',
    params
  });
}

// 匹配红人
export function postAddOrUpdReds(data = {}) {
  return fetch({
    url: '/api/promote/kolRequireReds/addOrUpdReds',
    method: 'post',
    data
  });
}

// kol需求效果单条查询
export function getRequireRedsById(params = {}) {
  return fetch({
    url: '/api/promote/kolRequireReds/getRequireRedsById',
    method: 'get',
    params
  });
}

// 获取红人的kol数量和花费
export function getRedsNumAndReferralFee(params = {}) {
  return fetch({
    url: '/api/promote/kolRequireReds/getRedsNumAndReferralFee',
    method: 'get',
    params
  });
}

// kol需求导出功能
export function getExportKolData(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/promote/kolRequire/exportKolData', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() },
        paramsSerializer: params => {
          let p = Object.keys(params).reduce((acc, next) => {
            if (params[next] !== null) {
              acc[next] = params[next];
            }
            return acc;
          }, {});
          return qs.stringify(p, { arrayFormat: 'repeat' });
        }
      })
      .then(res => {
        let { data } = res;
        let fileName = 'kol需求';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// kol列表导出功能
export function getExportKolPool(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/promote/kolPool/exportKolPool', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() },
        paramsSerializer: params => {
          let p = Object.keys(params).reduce((acc, next) => {
            if (params[next] !== null) {
              acc[next] = params[next];
            }
            return acc;
          }, {});
          return qs.stringify(p, { arrayFormat: 'repeat' });
        }
      })
      .then(res => {
        let { data } = res;
        let fileName = 'kol列表';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// kol需求提交
export function postSubmitKolRequire(data = {}) {
  return fetch({
    url: '/api/promote/kolRequire/submitKolRequire',
    method: 'post',
    data
  });
}

// 币别
export function getSelectAllCurrency(params = {}) {
  return fetch({
    url: '/api/base/baseCountry/selectAllCurrency',
    method: 'get',
    params
  });
}

// 获取物流商列表
export function getLogisticsList(params = {}) {
  return fetch({
    url: '/api/promote/kolRedsLogistics/getLogisticsList',
    method: 'get',
    params
  });
}

// 获取物流信息
export function getRedsLogistics(params = {}) {
  return fetch({
    url: '/api/promote/kolRedsLogistics/getRedsLogistics',
    method: 'get',
    params
  });
}

// kol需求审核分配和停止
export function postAuditKolProcessBatch(data = {}) {
  return fetch({
    url: '/api/promote/kolRequire/auditKolProcessBatch',
    method: 'post',
    data
  });
}

// kol需求删除
export function postDeleteKolRequireById(data = {}) {
  return fetch({
    url: '/api/promote/kolRequire/deleteKolRequireById',
    method: 'post',
    data
  });
}

// 查询页面表单提交
export function postSubmitIdKolRequire(data = {}) {
  return fetch({
    url: '/api/promote/kolRequire/submitIdKolRequire',
    method: 'post',
    data
  });
}

// 新增物流信息
export function postAddOrUpdLogitics(data = {}) {
  return fetch({
    url: '/api/promote/kolRedsLogistics/addOrUpdLogitics',
    method: 'post',
    data
  });
}

// 新增合作评价
export function postAddEvaluate(data = {}) {
  return fetch({
    url: '/api/promote/kolRedsCooperateEvaluate/addEvaluate',
    method: 'post',
    data
  });
}

// 新增批注
export function postAddRequireLog(data = {}) {
  return fetch({
    url: '/api/promote/kolRequireAuitLog/addRequireLog',
    method: 'post',
    data
  });
}

// 下载附件
export function postDownLoadZipFile(data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        '/api/promote/kolRequire/downLoadZipFile',
        { ...data },
        { responseType: 'blob', headers: { Authorization: getToken() } }
      )
      .then(res => {
        let { data } = res;
        let fileName = '文件下载';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 查询kol类别列表
export function getQueryKolCategoryList(params = {}) {
  return fetch({
    url: '/api/promote/kolCategory/queryKolCategoryList',
    method: 'get',
    params
  });
}

// kol类别删除
export function getDeleteKolCategory(params = {}) {
  return fetch({
    url: '/api/promote/kolCategory/deleteKolCategory',
    method: 'get',
    params
  });
}

// 查询所有类别
export function getAllKolCategory(params = {}) {
  return fetch({
    url: '/api/promote/kolCategory/getAllKolCategory',
    method: 'get',
    params
  });
}

// Kol列表查询和单条查询
export function getQueryKolPoolList(params = {}) {
  return fetch({
    url: '/api/promote/kolPool/queryKolPoolList',
    method: 'get',
    params
  });
}

// 查询跟进信息
export function getPoolTrackListByPoolId(params = {}) {
  return fetch({
    url: '/api/promote/kolPoolTrack/getPoolTrackListByPoolId',
    method: 'get',
    params
  });
}

// 查询所有标签
export function getListPoolTag(params = {}) {
  return fetch({
    url: '/api/promote/kolPoolTag/getListPoolTag',
    method: 'get',
    params
  });
}

// 获取kol审核人信息
export function getKolAuditor(params = {}) {
  return fetch({
    url: '/api/promote/kolRequire/getKolAuditor',
    method: 'get',
    params
  });
}

// kol类别新增或更新
export function postAddOrUpdKolCategory(data = {}) {
  return fetch({
    url: '/api/promote/kolCategory/addOrUpdKolCategory',
    method: 'post',
    data
  });
}

// 新增和修改Kol列表数据
export function postAddOrUpdKolPool(data = {}) {
  return fetch({
    url: '/api/promote/kolPool/addOrUpdKolPool',
    method: 'post',
    data
  });
}

// 删除Kol列表数据
export function postDeleteKolPool(data = {}) {
  return fetch({
    url: '/api/promote/kolPool/deleteKolPool',
    method: 'post',
    data
  });
}

// 标记黑名单
export function postAddBlackKolPool(data = {}) {
  return fetch({
    url: '/api/promote/kolPool/addBlackKolPool',
    method: 'post',
    data
  });
}

// 新增跟进信息
export function postAddPoolTack(data = {}) {
  return fetch({
    url: '/api/promote/kolPoolTrack/addPoolTack',
    method: 'post',
    data
  });
}

// 编辑标签
export function postAddOrUpdPoolTag(data = {}) {
  return fetch({
    url: '/api/promote/kolPoolTag/addOrUpdPoolTag',
    method: 'post',
    data
  });
}

// kol列表上传导入
export function postImportKolPool(data = new FormData()) {
  return fetch({
    url: '/api/promote/kolPool/importKolPool',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  });
}
