import request from 'utils/fetch';
import { Message } from 'element-ui';

// 因为当前返回以resCode做判断，所以暂时对请求方法做个全局判断
let fetch = (params = {}) => {
  return request(params).then(res => {
    if (res.resCode === 200) {
      return res;
    } else {
      Message.error(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
};

// 平均周星级统计
export function getStarAvaWeekStatis(params = {}) {
  return fetch({
    url: '/api/reviewer/reviewerStarAvaWeek/starAvaWeekStatis',
    method: 'get',
    params
  });
}

// 平均周星级明细
export function getListStarAvaWeekPage(params = {}) {
  return fetch({
    url: '/api/reviewer/reviewerStarAvaWeek/listStarAvaWeekPage',
    method: 'get',
    params
  });
}

// 星级变动明细
export function getListStarChangeDayPage(params = {}) {
  return fetch({
    url: '/api/reviewer/reviewerStarChangeWeek/listStarChangeWeekPage',
    method: 'get',
    params
  });
}

// 每周星级变更
export function getStarChangeWeekStatistics(params = {}) {
  return fetch({
    url: '/api/reviewer/reviewerStarChangeWeek/statistics',
    method: 'get',
    params
  });
}

// 每日rating
export function getQueryProductRatingList(params) {
  return fetch({
    url: '/api/reviewer/reviewerStarProductRating/queryProductRatingList',
    method: 'get',
    params
  });
}

// 获取品类
export function getCategoryByGroupName(params = {}) {
  return fetch({
    url: '/api/reviewer/sysProductDetail/getCategoryByGroupName',
    method: 'get',
    params
  });
}

// 获取ASIN
export function getAsinByGroupName(params = {}) {
  return fetch({
    url: '/api/reviewer/sysProductDetail/getAsinByGroupName',
    method: 'get',
    params
  });
}

// 获取运营人员
export function getSellerByGroupName(params = {}) {
  return fetch({
    url: '/api/reviewer/sysProductDetail/getSellerByGroupName',
    method: 'get',
    params
  });
}

// 获取公司sku
export function getSkuByStar(params = {}) {
  return fetch({
    url: '/api/reviewer/sysProductDetail/getSkuByStar',
    method: 'get',
    params
  });
}

// 获取型号
export function getTypeCodeByStar(params = {}) {
  return fetch({
    url: '/api/reviewer/sysProductDetail/getTypeCodeByStar',
    method: 'get',
    params
  });
}
