import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import mail from './modules/mail';
import tableField from './modules/table-field';
import permission from './modules/permission';
import common from './modules/common';
import kol from './modules/kol';
import sale from './modules/sale';
import account from './modules/account';
import b2b from './modules/b2b';
import cost from './modules/cost';
import budget from './modules/budget';
import hive from './modules/hive';
import getters from './getters';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    common,
    mail,
    tableField,
    kol,
    sale,
    account,
    b2b,
    cost,
    budget,
    hive
  },
  getters
});

export default store;
