import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/mailManager',
    component: Layout,
    name: '邮件中心',
    authority: 'mailManager',
    redirect: '/mailManager/my-mails',
    children: [
      {
        path: 'write-mails',
        component: () => import('@/views/mail-center/mail-box/create'),
        name: '写邮件',
        authority: 'write-mails'
      },
      {
        path: 'my-mails',
        component: () => import('@/views/mail-center/mail-box/list'),
        name: '我的邮件',
        authority: 'my-mails',
        hidden: true
      },
      {
        path: 'mails-detail',
        component: () => import('@/views/mail-center/mail-box/detail'),
        name: '邮件详情',
        authority: 'mails-detail'
      },
      {
        path: 'add-template',
        component: () => import('@/views/mail-center/mail-box/add'),
        name: '添加模板',
        authority: 'add-template'
      },
      {
        path: 'sendList',
        component: () => import('@/views/mail-center/mail-manager/send-list'),
        name: '发件列表',
        authority: 'sendList'
      },
      {
        path: 'mailPool',
        component: () => import('@/views/mail-center/mail-manager/mail-pool'),
        name: '邮件池',
        authority: 'mailPool'
      },
      {
        path: 'drafts',
        component: () => import('@/views/mail-center/mail-manager/drafts'),
        name: '草稿箱',
        authority: 'drafts'
      },
      {
        path: 'onlineTemplate',
        component: () => import('@/views/mail-center/mail-manager/online-template'),
        name: '线上模板',
        authority: 'onlineTemplate'
      }
    ]
  },
  {
    path: '/mailAdministrate',
    component: Layout,
    name: '邮箱管理',
    authority: 'mailAdministrate',
    children: [
      {
        path: 'pullException',
        component: () => import('@/views/mail-center/mail-manager/pull-exception'),
        name: '拉取异常',
        authority: 'pullException'
      },
      {
        path: 'pullConfigure',
        component: () => import('@/views/mail-center/mail-manager/pull-configure'),
        name: '拉取配置',
        authority: 'pullConfigure'
      },
      {
        path: 'pullRecord',
        component: () => import('@/views/mail-center/mail-manager/pull-record'),
        name: '拉取记录',
        authority: 'pullRecord'
      },
      {
        path: 'distributionRule',
        component: () => import('@/views/mail-center/mail-manager/distribute-rule'),
        name: '分配规则',
        authority: 'distributionRule'
      },
      {
        path: 'tagManager',
        component: () => import('@/views/mail-center/mail-manager/tag-manager'),
        name: '标签管理',
        authority: 'tagManager'
      },
      {
        path: 'accountManager',
        component: () => import('@/views/mail-center/mail-manager/account-manager'),
        name: '账号管理',
        authority: 'accountManager'
      }
    ]
  }
];
