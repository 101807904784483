import { getQueryCountryLanguage, getServiceMailboxList } from '@/api/hive/index';

let queryCountryLanguagePromise = null;
let serviceMailboxListPromise = null;
const common = {
  actions: {
    fetchQueryCountryLanguage({}, refresh) {
      if (queryCountryLanguagePromise && !refresh) {
        return queryCountryLanguagePromise;
      } else {
        queryCountryLanguagePromise = new Promise((resolve, reject) => {
          getQueryCountryLanguage()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return queryCountryLanguagePromise;
      }
    },
    fetchServiceMailboxList({}, refresh) {
      if (serviceMailboxListPromise && !refresh) {
        return serviceMailboxListPromise;
      } else {
        serviceMailboxListPromise = new Promise((resolve, reject) => {
          getServiceMailboxList()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return serviceMailboxListPromise;
      }
    }
  }
};

export default common;
