import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import store from '../store';
import { getToken } from 'utils/auth';
import qs from 'qs';

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  timeout: 15000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      config.paramsSerializer = function(params) {
        let p = Object.keys(params).reduce((acc, next) => {
          if (params[next] !== null) {
            acc[next] = params[next];
          }
          return acc;
        }, {});

        return qs.stringify(p, { arrayFormat: 'repeat' });
      };
    }

    // Do something before request is sent
    if (store.getters.token) {
      config.headers['Authorization'] = getToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }

    if (store.getters.userId) {
      // 让每个请求携带userId
      if (config.params !== undefined) {
        config.params.userId = store.getters.userId;
      }
      if (config.data !== undefined && !config.url.includes('/api/base/baseFieldsConfig')) {
        config.data.userId = store.getters.userId;
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
     * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
     */
    const res = response.data;

    if (typeof res.resCode === 'undefined') {
      const { url } = response.config;
      console.log(`${url} 检测到该接口返回结构不符合规范`);
    }

    if (res.resCode === 401) {
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('FedLogOut').then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
      return Promise.reject('error');
    } else if (response.status === 500) {
      Message({
        message: '系统后台异常，请联系技术支持！',
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject('error');
    }

    if (res.resCode === 403) {
      Message({
        message: '当前用户无相关操作权限，请重新登录！',
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject('error');
    }
    if (res.resCode === 402) {
      Message({
        message: '账户或密码错误！',
        type: 'warning'
      });
      return Promise.reject('error');
    }
    if (response.status !== 200 && res.status !== 200 && res.resCode !== 200) {
      Message({
        message: (res && res.resDes) || '系统后台异常，请联系技术支持！',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      return response.data;
    }
  },
  error => {
    const { code, message, config = {} } = error;

    const { method = '' } = config;

    if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1 && method === 'get') {
      Message({
        message: '当前查询超时，请输入更精准的条件进行搜索',
        type: 'error',
        duration: 5 * 1000
      });
    } else if (error.message === 'Network Error') {
      Message({
        message: '网络异常，请联检查网络连接！',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      Message({
        message: '系统后台异常，请联CRM技术支持！',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(error);
  }
);

export default service;
