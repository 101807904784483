import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'normalize.css/normalize.css'; // normalize.css 样式格式化
import 'element-ui/lib/theme-chalk/index.css';
import 'assets/custom-theme/index.css'; // 换肤版本element-ui css
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条 样式

import 'assets/iconfont/iconfont'; // iconfont

import * as filters from './filters'; // 全局vue filter
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

import Multiselect from 'vue-multiselect'; // 使用的一个多选框组件，element-ui的select不能满足所有需求
import 'vue-multiselect/dist/vue-multiselect.min.css'; // 多选框组件css
import Sticky from 'components/Sticky'; // 粘性header组件
import IconSvg from 'components/Icon-svg'; // svg 组件
Vue.component('multiselect', Multiselect);
Vue.component('Sticky', Sticky);
Vue.component('icon-svg', IconSvg);

import vueWaves from './directive/waves'; // 水波纹指令
Vue.use(vueWaves);

import auth from './directive/auth';
Vue.directive('auth', auth);

import errLog from 'store/errLog'; // error log组件

import { getToken, setUserId, setUserName } from 'utils/auth';

import 'babel-polyfill'; // 支持IE执行原生script

import './icons'; // icon
import echarts from 'echarts';
import moment from 'moment';
import ElementUI from 'element-ui';
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.prototype.$trim = str => str.replace(/^\s+|\s+$/g, '');

import tinymce from 'tinymce';
import VueTinymce from '@tinymce/tinymce-vue';
Vue.prototype.$tinymce = tinymce; // 将全局tinymce对象指向给Vue作用域下
Vue.component('VueTinymce', VueTinymce);

import Print from 'vue-print-nb';
Vue.use(Print);

// register global progress.
const whiteList = ['/login', '/authredirect']; // 不重定向白名单
router.beforeEach((to, from, next) => {
  NProgress.start(); // 开启Progress
  if (getToken()) {
    // 判断是否有token
    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      if (store.getters.menus === undefined) {
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetInfo')
          .then(info => {
            // 拉取user_info
            setUserId(info.id);
            setUserName(info.name);
            const menus = {};
            for (let i = 0; i < info.menus.length; i++) {
              menus[info.menus[i].code] = true;
            }
            store.dispatch('GenerateRoutes', menus).then(() => {
              // 生成可访问的路由表
              router.addRoutes([
                ...store.getters.addRouters,
                {
                  path: '*',
                  redirect: '/dashboard'
                }
              ]); // 动态添加可访问路由表
              next({ ...to }); // hack方法 确保addRoutes已完成
            });
          })
          .catch(() => {
            store.dispatch('FedLogOut').then(() => {
              next({ path: '/login' });
            });
          });
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next('/login'); // 否则全部重定向到登录页
      NProgress.done(); // 在hash模式下 改变手动改变hash 重定向回来 不会触发afterEach 暂时hack方案 ps：history模式下无问题，可删除该行！
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});

// 生产环境错误日志
if (process.env.NODE_ENV === 'production') {
  Vue.config.errorHandler = function(err, vm) {
    console.log(err, window.location.href);
    errLog.pushLog({
      err,
      url: window.location.href,
      vm
    });
  };
}

// 自定义组件，防止单个按钮多次点击导致重复请求，给组件增加v-singleClick属性即可
Vue.directive('singleClick', {
  inserted: function(el) {
    el.addEventListener('click', () => {
      el.disabled = true;
      setTimeout(() => {
        el.disabled = false;
      }, 1000);
    });
  }
});
// 搜索部分折叠
Vue.prototype.collapses = function() {
  if (this.collapseFlag === 1) {
    this.searchArea = document.querySelector('.collapse').offsetHeight;
    document.querySelector('.collapse').style.display = 'none';
    this.collapseBtn = 'el-icon-arrow-down collapseBtn';
    this.tableHeight = this.currentHeight + this.searchArea;
    this.collapseFlag = 0;
  } else {
    document.querySelector('.collapse').style.display = 'block';
    this.collapseBtn = 'el-icon-arrow-up collapseBtn';
    this.tableHeight = this.currentHeight;
    this.collapseFlag = 1;
  }
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// type='number'输入框限制输入两位小数
Vue.directive('doublefloat', {
  inserted: function(el) {
    el.addEventListener('keypress', e => {
      if (e.target.value !== '' && e.key === '.' && e.target.value.indexOf('.') === -1) {
        // 兼容火狐，数字输入框输入点 value 值自动隐藏‘.’，再进行正则会丢失‘.’
        return;
      }
      if (e.target.value.toString() !== e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) {
        e.target.value = e.target.value.match(/^\d*(\.?\d{0,1})/g)[0] || null;
      }
    });
  }
});
