// 删除节点dom
const remove = el => el && el.parentNode && el.parentNode.removeChild(el);

// 根据权限判断，删除节点
export default {
  inserted: (el, binding, vNode) => {
    const { $root: vm } = vNode.context;
    const auths = vm.$store.getters.elements;
    let { value, modifiers } = binding;
    const { reverse } = modifiers;

    if (!(typeof value === 'string') || !value) {
      remove(el);
      return console.error('please set the value to a string.');
    }
    if (typeof value === 'string') {
      value = auths[value];
      value = reverse ? !value : value;
    }
    if (!value) {
      remove(el);
    }
  }
};
