import fetch from 'utils/http';
import request from 'utils/fetch';
import { getToken } from 'utils/auth';
import axios from 'axios';
import qs from 'qs';
// 物流跟踪 - 列表查询
export function getLogisticsTrackPage(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanLogisticsTrack/logisticsTrackPage',
    method: 'get',
    params
  });
}

// 物流跟踪 -- 导出
export function getExportLogisticsTrack(params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get('/api/operate/supplyPlanLogisticsTrack/exportLogisticsTrack', {
        params,
        responseType: 'blob',
        headers: { Authorization: getToken() },
        paramsSerializer: params => {
          let p = Object.keys(params).reduce((acc, next) => {
            if (params[next] !== null) {
              acc[next] = params[next];
            }
            return acc;
          }, {});
          return qs.stringify(p, { arrayFormat: 'repeat' });
        }
      })
      .then(res => {
        let { data } = res;
        let fileName = '物流跟踪';
        resolve({ data, fileName });
      })
      .catch(err => {
        reject(err);
      });
  });
}

// 物流跟踪 - 导入
export function postImportLogisticsTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanLogisticsTrack/importLogisticsTrack',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  });
}

// 供应计划预览 - 列表查询
export function getSupplyPlanPage(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanMain/supplyPlanPage',
    method: 'get',
    params
  });
}

// 供应计划详情 - 查询备注信息
export function getQuerySupplySkuLog(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanSkuLog/querySupplySkuLog',
    method: 'get',
    params
  });
}

// 供应计划 - 添加备注
export function postAddSupplySkuLog(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanSkuLog/addSupplySkuLog',
    method: 'post',
    data
  });
}

// 供应计划预览 - 查看供应计划
export function getQuerySupplyPlanDetail(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanDetail/querySupplyPlanDetail',
    method: 'get',
    params
  });
}

// 编辑库存调整
export function postChangeInventory(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanChangeInventory/changeInventory',
    method: 'post',
    data
  });
}

// 查询仓库运输方式和运输时效
export function getSupplyPlanLogistics(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/supplyPlanLogistics',
    method: 'get',
    params
  });
}

// 供应计划 - 编辑保存供应计划
export function postSaveSupplyPlan(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanDetail/saveSupplyPlan',
    method: 'post',
    data
  });
}

// 供应计划 - 删除供应计划
export function postDeleteSupplyPlan(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanDetail/deleteSupplyPlan',
    method: 'post',
    data
  });
}

// 供应计划 - 导入待提po
export function postImportSupplyPlanPo(data = new FormData()) {
  return fetch({
    url: '/api/operate/supplyPlanPo/importSupplyPlanPo',
    headers: { 'Content-Type': 'multipart/form-data' },
    method: 'post',
    data
  });
}

// 供应计划-确认计划
export function getConfirmSupplyPlan(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanDetail/confirmSupplyPlan',
    method: 'get',
    params
  });
}

// 供应计划-导出
export function getAddSupplyPlanExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanMain/addSupplyPlanExcelTask',
    method: 'get',
    params
  });
}

// 供应计划 -- 历史数据下载
export function getHistoryExcel(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanMain/getHistoryExcel',
    method: 'get',
    params
  });
}

// 计划跟踪 - 列表查询
export function getSupplyPlanTrackPage(params = {}) {
  return fetch({
    url: '/api/operate//supplyPlanTrack/supplyPlanTrackPage',
    method: 'get',
    params
  });
}

// 查询目的仓和运输方式
export function getWarehouseTransportWay(params = {}) {
  return fetch({
    url: '/api/operate/saleBudgetLogisticsCost/getWarehouseTransportWay',
    method: 'get',
    params
  });
}

// 计划跟踪-导出
export function getAddPlanTrackExcelTask(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/addPlanTrackExcelTask',
    method: 'get',
    params
  });
}

// 计划跟踪 - 删除
export function postDelSupplyPlanTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/delSupplyPlanTrack',
    method: 'post',
    data
  });
}

// 计划跟踪 -- 保存
export function postAuditSupplyPlanTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/auditSupplyPlanTrack',
    method: 'post',
    data
  });
}

// 计划跟踪 - 保存并确认
export function postSaveConfirmSupplyPlanTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/saveConfirmSupplyPlanTrack',
    method: 'post',
    data
  });
}

// 计划跟踪 - 批量确认
export function postBatchConfirmPlanTrack(data = {}) {
  return request({
    url: '/api/operate/supplyPlanTrack/batchConfirmPlanTrack',
    method: 'post',
    data
  }).then(res => {
    const { resCode } = res;
    if ([200, 10001].some(code => code === resCode)) {
      return res;
    } else {
      Message.error(res ? res.resDes : '系统后台异常，请联CRM技术支持！');
      return Promise.reject(res);
    }
  });
}

// 计划跟踪 - 退回
export function postWithdrawPlanTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/withdrawPlanTrack',
    method: 'post',
    data
  });
}

// 计划跟踪 - 拆分
export function postSplitPlanTrack(data = {}) {
  return fetch({
    url: '/api/operate/supplyPlanTrack/splitPlanTrack',
    method: 'post',
    data
  });
}

// 供应计划 - 查询渠道品类
export function getSupplyChannelCategory(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanMain/supplyChannelCategory',
    method: 'get',
    params
  });
}

// 供应计划 - 标记完成
export function getSignSupplyPlan(params = {}) {
  return fetch({
    url: '/api/operate/supplyPlanMain/signSupplyPlan',
    method: 'get',
    params
  });
}

// export function (params = {}) {
//   return fetch({
//     url: '',
//     method: 'get',
//     params
//   });
// }

// export function (data = {}) {
//   return fetch({
//     url: '',
//     method: 'post',
//     data
//   });
// }
