import { getExchangeRateCurrency } from 'api/promote/otherReport';
import {
  getAllCompanyBank,
  getPaymentAllStatus,
  getAllCategoryByNow,
  getLastRate,
  getOrderStatus
} from '@/api/b2b/index';

let currencyPromise = null;
let allCompanyBankPromise = null;
let paymentAllStatusPromise = null;
let allCategoryByNowPromise = null;
let lastRatePromise = null;
let orderStatusPromise = null;

const common = {
  actions: {
    fetchExchangeRateCurrency({}, refresh) {
      if (currencyPromise && !refresh) {
        return currencyPromise;
      } else {
        currencyPromise = new Promise((resolve, reject) => {
          getExchangeRateCurrency()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return currencyPromise;
      }
    },
    fetchAllCompanyBank({}, refresh) {
      if (allCompanyBankPromise && !refresh) {
        return allCompanyBankPromise;
      } else {
        allCompanyBankPromise = new Promise((resolve, reject) => {
          getAllCompanyBank()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return allCompanyBankPromise;
      }
    },
    fetchPaymentAllStatus({}, refresh) {
      if (paymentAllStatusPromise && !refresh) {
        return paymentAllStatusPromise;
      } else {
        paymentAllStatusPromise = new Promise((resolve, reject) => {
          getPaymentAllStatus()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return paymentAllStatusPromise;
      }
    },
    fetchAllCategoryByNow({}, refresh) {
      if (allCategoryByNowPromise && !refresh) {
        return allCategoryByNowPromise;
      } else {
        allCategoryByNowPromise = new Promise((resolve, reject) => {
          getAllCategoryByNow()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return allCategoryByNowPromise;
      }
    },
    fetchLastRate({}, refresh) {
      if (lastRatePromise && !refresh) {
        return lastRatePromise;
      } else {
        lastRatePromise = new Promise((resolve, reject) => {
          getLastRate()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return lastRatePromise;
      }
    },
    fetchOrderStatus({}, refresh) {
      if (orderStatusPromise && !refresh) {
        return orderStatusPromise;
      } else {
        orderStatusPromise = new Promise((resolve, reject) => {
          getOrderStatus()
            .then(res => {
              resolve(res.data);
            })
            .catch(err => {
              reject(err);
            });
        });
        return orderStatusPromise;
      }
    }
  }
};

export default common;
