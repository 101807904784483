import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/psi',
    component: Layout,
    name: '进销存管理',
    authority: 'psi',
    redirect: '/psi/list',
    children: [
      {
        path: 'psi-list',
        component: () => import('@/views/psi/list'),
        name: '进销存报表',
        authority: 'psi-list'
      },
      {
        path: 'inventory',
        component: resolve => require(['@/views/inventory/list'], resolve),
        authority: 'inventory',
        name: '库存快照'
      }
    ]
  }
];
