import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/kol',
    component: Layout,
    name: 'kol',
    authority: 'kol',
    redirect: '/kol/kol-list',
    children: [
      {
        path: 'kol-list',
        component: () => import('@/views/kol/list'),
        name: 'KOL需求',
        authority: 'kol-list'
      },
      {
        path: 'kol-detail',
        component: () => import('@/views/kol/detail'),
        name: 'KOL效果追踪',
        authority: 'kol-detail'
      },
      {
        path: 'kol-category',
        component: () => import('@/views/kol/category'),
        name: 'KOL类别',
        authority: 'kol-category'
      },
      {
        path: 'kol-info-list',
        component: () => import('@/views/kol/info-list'),
        name: 'KOL列表',
        authority: 'kol-info-list'
      },
      {
        path: 'kol-info-detail',
        component: () => import('@/views/kol/info-detail'),
        name: 'KOL详情',
        authority: 'kol-info-detail'
      }
    ]
  }
];
